import {
  useCallback, useEffect,
  memo, useState
}                         from 'react';
import PropTypes          from 'prop-types';
import { useTranslation } from 'next-i18next';
import {
  Button,
  Modal
}                         from 'semantic-ui-react';
import UIModalClose       from 'components/UI/ModalClose';
import styles             from './feature-modal.module.scss';


const FeatureModal = ({ open, onClose }) => {
  const { t } = useTranslation('common');

  const getConfig = useCallback(() => {
    const conf = {
      className: styles.root,
      closeIcon: <UIModalClose onClick={onClose}/>,
      open,
      onClose,
      closeOnEscape: true,
      closeOnDimmerClick: false,
      size: 'small'
    };

    return conf;
  });

  return (
    <Modal {...getConfig()}>
      <Modal.Content>
        <div className={styles.modal_title}>
          {t('testFeatures.contentTitle')}
        </div>
        <div className={styles.text}>
          {t('testFeatures.contentText')}
        </div>
        <img
          className={styles.image}
          src="/images/feature-in-progress.png"
          alt="Раздел в разработке"
        />
        <div className={styles.button_container}>
          <Button
            color="teal"
            type="button"
            onClick={() => {
              if (window.Verbox && typeof(window.Verbox) === 'function') {
                window.Verbox("openSupport");
                onClose();
              }
            }}
          >
            {t('testFeatures.buttonText')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  )
};

FeatureModal.propTypes = {
  data:      PropTypes.object,
  garage:    PropTypes.object,
  open:      PropTypes.bool,
  onClose:   PropTypes.func,
  getVehicleWorkTimes: PropTypes.func,
};

FeatureModal.defaultProps = {
  open: false,
  onSubmit: () => {},
  onSuccess: () => {}
};

export default memo(FeatureModal);