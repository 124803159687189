import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import Shepherd from "shepherd.js";

import { ShepherdTourContext, TourState } from "./ShepherdTourContext";


const addSteps = (steps , tour) => {
  if (!steps.length) {
    return [];
  }
  const parsedStepsforAction = steps.map((step) => {
    const { buttons } = step;

    if (buttons) {
      step.buttons = buttons.map((button) => {
        const { action, classes, disabled, label, secondary, text, type } = button;
        return {
          action: type ? tour[type] : action,
          classes,
          disabled,
          label,
          secondary,
          text,
          type,
        };
      });
    }

    return step;
  });
  return parsedStepsforAction.forEach((step) => tour.addStep(step));
};


export function TourProvider({ children, settings, stepConstructor }) {
  const tourObject = useMemo(() => {
    const tour = new Shepherd.Tour(settings);
    addSteps(stepConstructor(tour), tour);
    return tour;
  }, [stepConstructor, settings]);

  const [state, setState] = useState({
    isActive: false,
    steps: tourObject.steps,
  });

  useEffect(() => {
    tourObject.on("show", ({ step: currentStep }) => {
      setState((state) => ({ ...state, currentStep, isActive: true }));
    });

    tourObject.on("complete", () => {
      setState((state) => ({ ...state, isActive: false, currentStep: undefined }));
    });

    tourObject.on("cancel", () => {
      setState((state) => ({ ...state, isActive: false, currentStep: undefined }));
    });
  }, [tourObject]);
const startTour=()=>{
  if(!state.isActive){
    tourObject.start()
  }else {return null}
}
  return (
    <ShepherdTourContext.Provider value={{ ...state,show: tourObject.show, start: startTour,next: tourObject.next, cancel: tourObject.cancel }}>
      {children}
    </ShepherdTourContext.Provider>
  );
}
