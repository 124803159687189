import {
  memo,
  useCallback,
}                         from 'react';
import PropTypes          from 'prop-types';
import { useRouter }      from 'next/router';
import { useTranslation } from 'next-i18next';
import { Modal }          from 'semantic-ui-react';
import DepotForm          from 'components/Forms/Depot';
import UIModalClose       from 'components/UI/ModalClose';
import styles             from './depot-edit-modal.module.scss';

const DepotEditModal = ({
  data, open, onClose, save, getDepots, update,
}) => {
  const {t} = useTranslation('common');
  const router = useRouter();
  const titleType = data?.id ? 'edit' : 'new';

  const submit = useCallback((model) => {
    save(model)
      .then((res) => {
        const {createDepot} = res.data;

        onClose();
        getDepots();

        if (update) {
          update();
        }

        if (createDepot) {
          router.push(`/stock/depots/details?id=${(createDepot.id).toString(16)}`);
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Modal
      className={styles.component}
      closeIcon={<UIModalClose onClick={onClose}/>}
      size="small"
      open={open}
      centered={true}
      onClose={onClose}
      closeOnEscape={true}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        {t(`common.depot.titles.${titleType}Depot`)}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <DepotForm
            data={data}
            submit={submit}
          />
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

DepotEditModal.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  save: PropTypes.func.isRequired,
  getDepots: PropTypes.func.isRequired,
};

DepotEditModal.defaultProps = {
  open: false,
  onSuccess: () => {
  },
};

export default memo(DepotEditModal);