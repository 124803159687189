import { useState } from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';
import styles from './side-menu-section.module.scss';

import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Popover from 'components/UI/Popover/Component';
import InfoIcon from '@material-ui/icons/InfoOutlined';
const SideMenuSection = ({ label, open, children, action, onToggle, popover }) => {
  const [expanded, setExpanded] = useState(open);
  const _expanded = onToggle ? open : expanded;
  return (
    <div className={styles.root}>
      <div
        className={cns(styles.header, {
          [styles.expanded]: _expanded
        })}
        onClick={() => {
          onToggle ? onToggle() : setExpanded(!expanded)
        }}
      >
        <div className={styles.arrow}>
          {_expanded ? <ArrowDown /> : <ArrowRight />}
        </div>

        <div className={styles.label}>
          {label}
        </div>

        <If condition={action && _expanded}>
          <div className={styles.action}>
            {action}
          </div>
        </If>
        {popover &&
          <Popover {...popover} />
        }
      </div>

      <If condition={_expanded}>
        <div className={styles.content}>
          {children}
        </div>
      </If>
    </div>
  )
};

SideMenuSection.propTypes = {
  label: PropTypes.string.isRequired,
  defaultOpened: PropTypes.bool
};

SideMenuSection.defaultProps = {
  defaultOpened: false
};

export default SideMenuSection;