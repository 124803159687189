import { memo } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import UserStory from 'components/User/Story';
import PhoneFormatter from 'components/Formatters/Phone';
import RolesFormatter from 'components/Formatters/Roles';
import UICircleButton from 'components/UI/CircleButton';
import { getUserName } from 'helpers/helpers';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import isFunction from 'lodash/isFunction';
import styles from './user-item.module.scss';

import EditIcon from '@material-ui/icons/Edit';
import PhoneIcon from '@material-ui/icons/Phone';
const returnName = (data) => {
  if (Object.keys(data).includes('fullName')) {
    return getUserName(data.fullName)
  }
  else { return getUserName(data.name) }
}
const UserItem = ({
  data, id, showPhoneBtn, showRole, canEdit, onEditClick, onUnSelect,
  route, href, linkProps,
}) => {
  // const { photos } = data;

  // --------------------------------------------------------------------

  const getContent = () => {
    return (
      <div className={styles.info} id={id}>
        <div className={styles.image}>
          <UserStory
            size="small"
          // image={photos ? sortBy(photos, 'id').reverse()[0].file.url : undefined}
          />
        </div>
        <div className={styles.data}>
          <div className={styles.name}>

            {returnName(data)}
            <If condition={showRole}>
              <span className={styles.role}>
                {' - '}<RolesFormatter value={data.roles} />
              </span>
            </If>
          </div>
          <div className={styles.phone}>
            <PhoneFormatter value={data.phone} asLink={false} />
          </div>
        </div>
      </div>
    )
  };

  // --------------------------------------------------------------------

  return (
    <div className={styles.root}>
      <Choose>
        <When condition={route}>
          <Link href={route}>
            <a>{getContent()}</a>
          </Link>
        </When>
        <When condition={href}>
          <a href={href} {...linkProps}>
            {getContent()}
          </a>
        </When>
        <Otherwise>
          {getContent()}
        </Otherwise>
      </Choose>

      <If condition={showPhoneBtn}>
        <div className={styles.phone_button}>
          <UICircleButton
            icon={<PhoneIcon />}
            positive
            inverted
            href={`tel:${data.phone}`}
            target="_blank"
          />
        </div>
      </If>

      <If condition={canEdit && isFunction(onEditClick)}>
        <div className={styles.edit_button}>
          <UICircleButton
            icon={<EditIcon />}
            onClick={onEditClick}
          />
        </div>
      </If>
    </div>
  )
};

UserItem.propTypes = {
  data: PropTypes.object.isRequired,
  showPhoneBtn: PropTypes.bool,
  canEdit: PropTypes.bool,
  onEditClick: PropTypes.func
};

UserItem.defaultProps = {
  showPhoneBtn: true,
  canEdit: false,
  showRole: false,
  asLink: false,
  linkProps: {}
};

export default memo(UserItem);