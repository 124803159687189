import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import {
  useProductEvents,
  useUser,
} from 'hooks';
import Page from './Component';
import {
  applyUser,
  resetUser,
  applyUserProfile
} from 'reducers/auth';
import { getDomainForCookie } from 'helpers/helpers';
import { TOKEN_COOKIE_NAME } from 'helpers/constants';
import { eraseCookie } from 'helpers/cookies';
import isEmpty from 'lodash/isEmpty';
import without from 'lodash/without';
import API from 'api';

function ConnectedPage(props) {
  const auth = useSelector(state => state.auth);
  const garage = useSelector(state => state.garage.data);
  const dispatch = useDispatch();
  const router = useRouter();
  const user = useUser();
  const events = useProductEvents();

  const getUser = (user) => {
    return dispatch(API.queries[`${user.type}Profile`]({
      where: { id: { eq: +user.id } }
    }));
  };

  const getGarage = (id) => {
    return dispatch(API.queries.garage({
      where: { id: { eq: id } }
    }));
  };

  const logout = () => {
    dispatch(API.queries.garage.reset());
    dispatch(API.queries.depots.reset());
    dispatch(resetUser());
    eraseCookie(TOKEN_COOKIE_NAME, getDomainForCookie());
    eraseCookie(TOKEN_COOKIE_NAME);
    router.replace(`/access?tab=auth&then=${router.asPath}`);
  };

  const checkAuth = () => {
    if (user !== false) {
      dispatch(applyUser(user));

      if (isEmpty(auth.profile)) {
        getUser(user)
          .then(res => {
            const _user = res.data[user.type];
            const tokenRoles = JSON.stringify(without(user.roles, 'EMPLOYEE'));
            const profileRoles = JSON.stringify(_user.roles);

            if (tokenRoles !== profileRoles) {
              logout();
            } else {
              dispatch(applyUserProfile(_user));

              const amplitudeProps = {
                userEmail: _user.email,
                userCreatedAt: _user.createdAt,
                workshopId: _user.garage?.id,
                workshopName: _user.garage?.name,
                workshopLegalName: _user.garage?.legalName,
                workshopEmail: _user.garage?.email,
                workshopPhone: _user.garage?.phone,
                workshopCreatedAt: _user.garage?.createdAt,
                workshopInn: _user.garage?.inn,
                workshopCity: _user.garage?.address?.city,
              }
              events.setUserProperties(amplitudeProps);
            }

            return Promise.resolve(_user);
          })
      } else {

      }
    } else {
      logout();
    }
  };

  useEffect(() => {
    const { profile } = auth;

    if (isEmpty(garage) && profile && profile.workshopId) {
      getGarage(profile.workshopId);
    }
  }, [auth.profile]);

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <Page
      {...props}
      auth={auth}
      user={user}
    />
  )
}

export default ConnectedPage;