import { useProductEvents } from 'hooks';
import { TouchPitchHandler } from 'mapbox-gl';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';
import styles from "./ui-popover.module.scss"

export default function Popover({ text, label, title, amplitude, style, icon }) {
    const [visible, setVisible] = useState(false)
    const router = useRouter();
    const events = useProductEvents();

    return (
        <div onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)} style={style}>
            <div className={styles.container}  >
                {icon}
                <p>{title}</p>
            </div>
            {visible && (
                <div className={styles.popover}>
                    {text}
                    {label &&
                        <Button
                            className={styles.button}
                            color='teal'
                            size='mini'
                            circular
                            onClick={() => {
                                router.push('/settings/garage/edit')
                                events.sendTo(['amplitude'], amplitude);
                            }}

                        >{label}</Button>
                    }

                </div>
            )}

        </div>
    );
}
