import SideMenuSection from './../../Section';
import SideMenuLeaf from './../../Leaf';
import { useTranslation } from 'next-i18next';
import BookingIcon from '@material-ui/icons/BookOutlined';
import PostLoadingIcon from '@material-ui/icons/HourglassEmptyOutlined';
import UICircleButton from '../../../../UI/CircleButton';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import styles from '../Repair/repair-module.module.scss';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useRef,
} from 'react';
import { useRouter } from 'next/router';
import endOfMonth from 'date-fns/endOfMonth';
import API from 'api';
import { EnumType } from 'json-to-graphql-query';
import isEmpty from 'lodash/isEmpty';
import getSideMenuSection from '../Favourites/list';

const SideMenuPlanningSection = ({ open, onToggle, sections, favourites, addFavourites, removeFavourites }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const intervalId = useRef(null);
  const now = new Date();
  const month = now.getMonth();
  const year = now.getFullYear();
  const garageBookings = useSelector(state => state.garageBookings);
  const getBookingsCountFromLocalStorage = () => {
    const count = localStorage.getItem('garageBookingsCount');
    if (count) {
      return +count;
    } else {
      setBookingsCountToLocalStorage(garageBookings?.data?.info?.totalCount || 0)
    }
  };

  const setBookingsCountToLocalStorage = (count) => {
    localStorage.setItem('garageBookingsCount', count);
  };

  /*useEffect(() => {
    getBookings();
  }, []);*/

  const getBookings = () => {
    const monthStart = new Date(year, +month, 1, 0, 0, 0, 0);
    const monthEnd = endOfMonth(monthStart);

    return dispatch(API.queries.garageBookings({
      where: {
        startAt: {
          gte: monthStart.toISOString(),
          lte: monthEnd.toISOString()
        }
      },
      paginate: { limit: 0 },
      order: { startAt: new EnumType('asc') }
    }))
  };

  /*useEffect(() => {
    intervalId.current = setInterval(() => {
      getBookings()
    }, 10000);

    return () => {clearInterval(intervalId.current)};
  }, []);*/

  return (
    <SideMenuSection
      label={t('employee.menu.scheduling')}
      open={open}
      onToggle={onToggle}
    >
      <div>

        {
          sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }
      </div>
      {/* <SideMenuLeaf
        icon={<BookingIcon />}
        text={t('employee.menu.clientsBooking')}
        route={`/planning/arrivals/month/${new Date().getMonth()}`}
      />
      <SideMenuLeaf
        icon={<PostLoadingIcon />}
        text={t('employee.menu.postsLoading')}
        route="/planning/posts"
      /> */}
    </SideMenuSection>
  );
};

export default SideMenuPlanningSection;