import { useEffect }  from 'react';
import {
  useDispatch,
  useSelector,
}                     from 'react-redux';
import EmployeeLayout from './Layout';
import API             from 'api';
import isEmpty         from 'lodash/isEmpty';

function ConnectedEmployeeLayout(props) {
  const depots = useSelector(state => state.depots.data.items || []);
  const dispatch = useDispatch();

  const getDepots = () => {
    if (isEmpty(depots)) {
      return dispatch(API.queries.depots());
    }

    return Promise.resolve();
  };

  useEffect(() => {
    getDepots();
  }, []);

  return (
    <EmployeeLayout {...props}/>
  );
}

export default ConnectedEmployeeLayout;