import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './top-panel.module.scss';
import SearchIcon from '@material-ui/icons/Search';
import Image from 'next/image';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { isLocalStorageAvailable } from 'helpers';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useProductEvents } from 'hooks';
import { useWindowSize } from 'hooks/useWindowSIze';

const TopPanel = ({ title }) => {
  const toggleMenu = () => {
    document.body.classList.toggle('menu-opened');
  };

  const showFinder = () => {
    document.body.classList.toggle('search-visible');
  };

  const router = useRouter();
  const events = useProductEvents();
  const size = useWindowSize();


  return (
    <div>

      <div className={styles.component}>
        <div className={styles.left}>
          <div
            className={styles.menu_button}
            onClick={toggleMenu}
          >
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        </div>

        <div className={styles.center}>
          <div className={styles.title}>{title}</div>
        </div>

        <div className={styles.right}>
          <div
            className={styles.search}
            onClick={showFinder}
          >
            <SearchIcon />
          </div>
        </div>
      </div>
    </div>
  )
};

TopPanel.propTypes = {
  title: PropTypes.string.isRequired
};

export default memo(TopPanel);