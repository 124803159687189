import {
  useSelector,
  useDispatch
}                                    from 'react-redux';
import { toggleNotFeatureModal }	   from 'reducers/app';
import FeatureModal                  from './Component'

function ConnectedFeatureModal (props) {
  const visibleModal = useSelector(state => state.app.visibleNotFeatureModal);
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(toggleNotFeatureModal(false));
  }

  return (
    <FeatureModal
      open={visibleModal}
      onClose={onClose}
      {...props}
    />
  )
}

export default ConnectedFeatureModal;