import { useTranslation } from 'next-i18next';

const VehicleModificationFormatter = ({ value }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <If condition={value}>
        <img style={{ width: '1.5rem' }} src={`/images/kinds/${value}.svg`} alt={t(`common.vehicle.kind.types.${value}`)}
          title={t(`common.vehicle.kind.types.${value}`)} />
      </If>
      <If condition={!value}>
        <img style={{ width: '1.5rem' }} src={`/images/kinds/CAR.svg`} alt={t(`common.vehicle.kind.types.CAR`)}
          title={t(`common.vehicle.kind.types.CAR`)} />
      </If>
    </>

  );
};

export default VehicleModificationFormatter;