import { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import SideMenu from './Component';
import {
  toggleAppMenuSectionState,
  setAppMenuScrollPosition,
  loadMenuSections,
} from 'reducers/app';
import { isLocalStorageAvailable } from 'helpers';

function ConnectedSideMenu(props) {
  const menuScrollPosition = useSelector(state => state.app.menuScrollPosition);
  const method = useSelector(state => state.auth.method);
  let sections = useSelector(state => state.app.menu)
  const dispatch = useDispatch();
  if (sections == null) {
    sections = {
      planning: true,
      repairs: true,
      crm: false,
      depot: false,
      settings: false,
      help: false
    }
  }
  useEffect(() => {
    if (isLocalStorageAvailable()) {// проверяем готов ли localStorage
      const menuFromStorage = localStorage.getItem('menuSections');
      if (menuFromStorage == null) { // если готов, но там пусто, инициируем значение
        localStorage.setItem('menuSections', JSON.stringify(sections))
        dispatch(loadMenuSections(sections))
      } else {// если есть значение в localStorage
        const sectionsMenu = JSON.parse(menuFromStorage)// пытаемся взять значение из localStorage
        dispatch(loadMenuSections(sectionsMenu))// синхронизируем его с редуксом
        sections = sectionsMenu;
      }
    }
  }, [])

  return (
    <SideMenu
      menuScrollPosition={menuScrollPosition}
      method={method}
      sections={sections}
      {...bindActionCreators({
        toggleAppMenuSectionState,
        setAppMenuScrollPosition
      }, dispatch)}
      {...props}
    />
  )
};

export default memo(ConnectedSideMenu);