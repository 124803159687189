import { useTranslation } from 'next-i18next';
import RepairListItem     from 'components/Repair/Item';
import styles             from './finder-repair-item.module.scss';

function FinderRepairItem ({ data }) {
  const { t } = useTranslation('common');

  return (
    <div className={styles.root}>
      <RepairListItem
        data={data}
        canEdit={false}
        asSimpleLink={true}
        linkProps={{
          target: "_blank"
        }}
      />
    </div>
  )
};

export default FinderRepairItem;