import { useEffect } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import GarageBalance from './Component';
import API from 'api';
import isEmpty from 'lodash/isEmpty';
import { setCookie } from 'helpers/cookies';
import { SUBSCRIPTION_END_DATE_COOKIE_NAME } from 'helpers/constants';

function ConnectedGarageBalance(props) {
  const billAccount = useSelector(state => state.billAccount.data);
  const dispatch = useDispatch();

  // console.log('billAccount', billAccount);

  const getBillAccount = () => {
    return dispatch(API.queries.billAccount());
  };

  useEffect(() => {
    getBillAccount()
      .then(resp => {
        if (resp.data?.billAccount?.subscriptionEndDate) {
          setCookie(SUBSCRIPTION_END_DATE_COOKIE_NAME, resp.data?.billAccount?.subscriptionEndDate);
        }
      });
  }, []);

  return (
    <If condition={!isEmpty(billAccount)}>
      <GarageBalance
        data={billAccount}
        update={getBillAccount}
        {...props}
      />
    </If>
  );
}

export default ConnectedGarageBalance;