import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Finder from './Component';
import API from 'api';
import { applyQuery } from 'reducers/finder';
import { EnumType } from 'json-to-graphql-query';

function ConnectedFinder(props) {
  const { query } = useSelector(state => state.finder);
  const { loading, data } = useSelector(state => state.globalSearch);
  const dispatch = useDispatch();

  const find = (q) => {
    return dispatch(API.queries.globalSearch({
      where: { q },
      order: { id: new EnumType('desc') },
      paginate: { page: 1, limit: 10 }
    }));
  };

  const reset = () => {
    dispatch(API.queries.globalSearch.reset());
  };

  return (
    <Finder
      loading={loading}
      data={data}
      find={find}
      query={query}
      onChange={(q) => { dispatch(applyQuery(q)) }}
      reset={reset}
      {...props}
    />
  )
};

export default memo(ConnectedFinder);