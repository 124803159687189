import { useTranslation } from 'next-i18next';

const RolesFormatter = ({ value }) => {
  const { t } = useTranslation('common');

  return (
    <If condition={value}>
      <For each="role" of={value} index="index">
        <span key={role} className="role-formatter">
          {t(`common.user.roles.${role}`)}
          <If condition={index < value.length - 1}>
            {', '}
          </If>
        </span>
      </For>
    </If>
  )
}

export default RolesFormatter;