import { memo }           from 'react';
import { useTranslation } from 'next-i18next';
import CloseIcon          from '@material-ui/icons/Close';
import styles             from './ui-modal-close.module.scss';

const UIModalClose = ({ onClick }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.root} onClick={onClick}>
      <CloseIcon/>
    </div>
  )
};

export default memo(UIModalClose);