import PropTypes    from 'prop-types';
import UIDate       from 'components/UI/Date';
import VehiclePlate from './../Component';
import styles       from './vehicle-plate-item.module.scss';

const VehiclePlateItem = ({ data }) => {
  return (
    <div className={styles.component}>
      <div className={styles.date}>
        <UIDate date={data.createdAt}/>
      </div>
      <div className={styles.value}>
        <VehiclePlate value={data.value}/>
      </div>
    </div>
  )
};

VehiclePlateItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default VehiclePlateItem;