/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Step, Tour } from 'react-shepherd';
import { offset, shift, inline, limiter, limitShift } from '@floating-ui/dom';

import { TourStepOptionsWithType } from './types';

import styles from './shepherd.module.scss';

const addProgressBar = (currentStep, tour) => {
  const header = document.querySelector(`#${currentStep.id}-description`);
  const progressBar = document.createElement('div');
  // const progressText = document.createElement("div");
  // progressText.className = styles.progress_text;
  progressBar.className = styles.bar;
  const progress = (tour.steps.indexOf(currentStep) + 1) / tour.steps.length;
  // progressText.innerText = `Этап ${tour.steps.indexOf(currentStep)} / ${tour.steps.length}`;
  const bar = document.createElement('div');
  bar.style.width = `${progress * 100}%`;
  bar.style.height = '100%';
  bar.style.backgroundColor = 'white';
  bar.style.borderTopLeftRadius = '5px';
  bar.style.borderBottomLeftRadius = '5px';
  progressBar.append(bar);
  // header?.append(progressText);
  header?.append(progressBar);
};

const customButtons = [
  {
    type: 'back',
    classes: styles.button_next,
    text: '◀◀',
  },
  {
    classes: styles.button_next,
    type: 'cancel',
    text: '✖',
  },
  {
    classes: styles.button_next,
    type: 'next',
    text: '▶▶',
  },
];

const fillingInputs = (id) => {
  const parent = document.querySelector(`#${id}`);
  const arrInputs = Array.of(parent.querySelectorAll('input'));
  if (arrInputs.length === 0) {
    return true;
  }
  return arrInputs.every((input) => input.value);
};

function addSteps(tour) {
  return [
    // Этап 1 Создание ЗН
    {
      id: 'welcome',
      title: 'Это страница создания Заказ-наряда',
      text: [
        `
      <p>
Здесь можно добавить автомобиль, участников и причину обращения. </p>
      <p>   Этого будет достаточно для быстрого создания первого заказ-наряда
      </p>
      `,
      ],

      classes: styles.frame,
      buttons: [
        {
          type: 'cancel',
          classes: styles.button,
          text: 'В другой раз',
        },
        {
          type: 'next',
          classes: styles.button,
          text: 'Начать',
        },
      ],
    },
    {
      id: 'vehicle',
      title: 'Добавление автомобиля',
      text: 'Добавленный автомобиль сохранится в базе и вы сможете его найти в пару кликов для создания нового заказ-наряда или просмотра истории ремонта',

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 10 })],
      },
      attachTo: { element: '#vehicle', on: 'bottom' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          // const currentStep = Shepherd.activeTour?.getCurrentStep()
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 20,
      buttons: customButtons,
    },
    {
      id: 'vin',
      title: 'VIN и гос. номер',
      text: 'Если автомобиль уже есть в вашей базе, то по VIN или гос. номеру я заполню все поля за вас',

      floatingUIOptions: {
        middleware: [offset({ mainAxis: -150, crossAxis: 0 })],
      },
      attachTo: { element: '#vin', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 10,
      buttons: customButtons,
    },
    {
      id: 'setauto',
      title: 'Данные автомобиля',
      text: 'По марке, модели, модификации подберется каталог работ из Автодаты',

      floatingUIOptions: {
        middleware: [offset({ mainAxis: -150, crossAxis: 0 })],
      },
      attachTo: { element: '#auto', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 10,
      buttons: customButtons,
    },
    {
      id: 'auto',
      title: 'Данные автомобиля',
      text: 'По марке, модели, модификации подберется каталог работ из Автодаты',

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      attachTo: { element: '#auto', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 10,
      buttons: customButtons,
    },
    {
      id: 'autoautomilliage',
      title: 'Пробег автомобиля',
      text: 'Актуализируйте пробег автомобиля при каждой возможности, тогда вы сможете предлагать клиентам плановое обслуживание, на основании пробега',

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 25, crossAxis: 0 })],
      },
      attachTo: { element: '#automileage', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 10,
      buttons: customButtons,
    },
    {
      id: 'menuvehicle',
      title: 'Раздел Автомобили',
      text: 'Здесь хранится вся информация по добавленным ранее автомобилям',
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 40, crossAxis: 0 })],
      },
      attachTo: { element: '#menuvehicle', on: 'left' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 0,
      buttons: customButtons,
    },
    {
      id: 'persons',
      title: 'Добавление участников',
      text: 'Введите данные всех участников, если водитель и владелец одно лицо, то поставьте галочку в “Тот же, что и владелец” и мы заполним поле автоматически',
      modalOverlayOpeningPadding: 125,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 120, crossAxis: 0 })],
      },
      attachTo: { element: '#persons', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 20,

      buttons: customButtons,
    },
    {
      id: 'clients',
      title: 'Участники',
      text: 'Введенные значения можно редактировать или удалить',
      modalOverlayOpeningPadding: 8,

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 25, crossAxis: 0 })],
      },

      attachTo: { element: '#owner', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,

      buttons: customButtons,
    },
    {
      id: 'sideclient',
      title: [
        `
      <p>
      Можно будет выбрать сотрудника,
      </p>
      <p>
      который ведет этот заказ-наряд,
      </p>
<p> а я за вас посчитаю, сколько</p>
      <p>
       он заработал за этот заказ-наряд
      </p>
      `,
      ],
      text: 'Сотрудника можно добавить через этот раздел, дайте ему роль мастера-приёмщика. В карточке Сотрудника есть кнопка “Задать правила расчета” зарплаты',
      attachTo: { element: '#sideclient', on: 'left' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 40, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      buttons: customButtons,
    },
    {
      id: 'description',
      title: 'Причина обращения',
      text: 'Здесь можно добавить заметку от клиента, чтобы не забыть о деталях ремонта',
      modalOverlayOpeningPadding: 25,
      attachTo: { element: '#description', on: 'top' },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,

      buttons: customButtons,
    },
    {
      id: 'create',
      title: 'Почти готово',
      text: [`Создание заказ-наряда. Нажмите сюда, чтобы пройти дальше`],
      attachTo: { element: '#create', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      canClickTarget: true,
      modalOverlayOpeningPadding: 30,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 35, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: [
        {
          type: 'back',
          classes: styles.button_next,
          text: '◀◀',
        },
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
      ],
    },

    // ----------------------------------------------------------------------------------------//

    // Этап 2 Редактирование Part I

    {
      id: 'edit',
      title: 'Черновик заказ-наряда создан',
      text: 'А здесь Вы можете его дополнить и подготовить к печати',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      buttons: [
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
        {
          classes: styles.button_next,
          type: 'next',
          text: '▶▶',
          action() {
            fillingInputs('vin');
          },
        },
      ],
    },
    {
      id: 'status',
      title: 'Статус заказ-наряда',
      text: 'Заказ-наряд создан в статусе Черновик. Здесь Вы можете узнать больше о статусах',
      attachTo: { element: '#status', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 5,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      buttons: customButtons,
    },
    {
      id: 'openstatus',
      attachTo: { element: '', on: 'top' },
    },
    {
      id: 'statusmodal',
      title: 'Информация по статусам',
      text: 'Статусы позволяют легко отслеживать, на каком этапе находится каждый заказ-наряд и обеспечивают более эффективное управление процессом обслуживания клиентов',
      attachTo: { element: '#statusmodal', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 5,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 50, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      buttons: customButtons,
    },
    {
      id: 'closestatus',
      attachTo: { element: '', on: 'top' },
    },
    {
      id: 'menuzn',
      title: [`<p>На этом этапе </p> <p>все данные сразу сохраняются </p>`],
      text: 'Не переживайте, я сразу сохраняю все произведенные изменения. Ваш заказ-наряд хранится в разделе ЗАКАЗ-НАРЯДЫ',
      attachTo: { element: '#menuzn', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 5,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      buttons: customButtons,
    },
    {
      id: 'edit',
      title: 'Теперь дополним Ваш заказ-наряд ',
      text: 'Давайте заполним данные о работах и запчастях',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningRadius: 10,
      modalOverlayOpeningPadding: 5,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      buttons: customButtons,
    },
    {
      id: 'worksbuttons',
      title: [
        `<p>Добавить работы можно из каталога</p> <p>или создать новую</p>`,
      ],
      text: 'К сожалению, не все машины есть в каталоге Автодаты, но я активно работаю над их пополнением, также вы можете создавать свои каталоги работ и создавать заказ-наряды в считанные секунды',
      attachTo: { element: '#worksbuttons', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 25,
      floatingUIOptions: {
        middleware: [shift({ mainAxis: true })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 35, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'opencatalog',
      title: 'Открытие модалки',
      text: 'Не обращайте внимания на это окно, идите дальше',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
    },
    {
      id: 'services',
      title: 'Добавление работы',
      text: 'Какую работу будете выполнять?',
      attachTo: { element: '#services', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'searchinputworks',
      title: 'Поиск по каталогу',
      text: 'Здесь можно быстро найти ранее добавленную работу',
      attachTo: { element: '#searchinputworks', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'openworks',
      title: 'Вторая модалка',
      text: 'Пум пум пуууум',
      attachTo: { element: '#openworks', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'servicesname',
      title: 'Добавление работы ',
      text: 'Какую работу будете выполнять?',
      attachTo: { element: '#servicesname', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'servicecount',
      title: 'Подсказка',
      text: 'Кол-во умножится на время за единицу работы для подсчета итоговой стоимости',
      attachTo: { element: '#servicecount', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 10 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'servicesprice',
      title: [
        `<p>Цену за нормо-час предлагется из </p>
      <p>настроек по умолчанию</p> `,
      ],
      text: 'Ее можно поменять через меню слева в разделе Настройки > Автосервис > Настройки автосервиса > СТОИМОСТЬ НОРМОЧАСА',
      attachTo: { element: '#servicesprice', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 10 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'servicesdiscount',
      title: 'Скидки!',
      text: 'По желанию можете сделать скидку в % или рублях',
      attachTo: { element: '#servicesdiscount', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 10 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'servicesclose',
      title: 'Сохранение работы',
      text: 'Нажмите, чтобы добавить работу в Заказ-наряд',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 10, crossAxis: 10 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'performers',
      title: [
        `<p>Вы можете указать исполнителя работы,</p>
      <p>чтобы им автоматически посчитались</p> 
      <p> выплаты по зарплате</p>`,
      ],
      text: [
        `<p>Исполнителем работы может быть сотрудник с ролью “Работник рем. зоны”.</p>
        <p>
      Для этого добавьте сотрудника через меню слева в разделе Настройки > Сотрудники, дайте ему роль работника ремзоны. В карточке Сотрудника есть кнопка “Задать правила расчета зарплаты” </p>`,
      ],
      attachTo: { element: '#performers', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 25,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 40, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'addperformers',
      title: 'Исполнители',
      text: 'Если работу выполняют 2 сотрудника, то можно поделить выплату по ним в процентах',
      attachTo: { element: '#addperformers', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 17,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
      buttons: customButtons,
    },
    {
      id: 'editperformers',
      title: 'А так же',
      text: 'Если один сотрудник, можно проставить его по умолчанию на всех работах',
      attachTo: { element: '#editperformers', on: 'left' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 20,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 27, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 20,
      buttons: customButtons,
    },
    {
      id: 'spareitems',
      title: 'Запчасти',
      text: 'В этом разделе можно добавить запчасти со склада. В скором времени можно будет даже делать проценки и подбирать кроссы - наша команда работает над этим!',
      attachTo: { element: '#spareitems', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 25,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 35, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'openspare',
      title: 'Открытие модалки запчастей',
      text: '',
      attachTo: { element: '#openspare', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 25,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },

    {
      id: 'stock',
      title: 'Принять товар на склад',
      text: 'Нажмите на эту кнопку, чтобы разобраться, как оформить запчасти через склад',
      attachTo: { element: '#stockbutton', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 25,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 50, crossAxis: 30 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: [
        {
          type: 'back',
          classes: styles.button_next,
          text: '◀◀',
        },
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
      ],
    },

    // -----------------------------------------------------------------------------------------------//
    // Этап 3 Заполнение Поставщиков

    {
      id: 'stock',
      title: 'Это страница приемки запчастей',
      text: 'Здесь Вы можете добавить запчасти на склад',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 25,

      modalOverlayOpeningRadius: 10,
      buttons: [
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
        {
          classes: styles.button_next,
          type: 'next',
          text: '▶▶',
        },
      ],
    },
    {
      id: 'counetrpartysearch',
      title: 'Запчасти',
      text: 'Вам как раз привезли запчасти, давайте вместе добавим их на склад',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      buttons: [
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
        {
          classes: styles.button_next,
          type: 'next',
          text: '▶▶',
        },
      ],
    },
    {
      id: 'counterparty',
      title: 'Добавление поставщика',
      text: 'Вы можете выбрать контрагента из списка ранее добавленных или создать нового',
      attachTo: { element: '#counterparty', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 30 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'opencounterparty',
      title: 'Добавление поставщика',
      text: 'Вы можете выбрать контрагента из списка ранее добавленных или создать нового',
      attachTo: { element: '', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'counterpartyinfo',
      title: 'Информация',
      text: 'Можно заполнить данные о контрагенте и всегда иметь под рукой его реквизиты, телефоны в разделе CRM > Контрагенты. Это необязательно, но вы всегда сможете к этому вернуться позже',
      attachTo: { element: '#counterpartyinfo', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 30 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'closecounterparty',
      title: 'close',
      text: '',
      attachTo: { element: '', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 10,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 40, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'counterpartysidemenu',
      title: 'Раздел Контрагенты',
      text: 'Здесь хранятся все добавленные ранее поставщики',
      attachTo: { element: '#closecounterparty', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 40, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'setcounterparty',
      title: 'setcounterparty',
      text: 'setcounterparty',
      attachTo: { element: '', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
    },
    {
      id: 'depot',
      title: 'Товарные позиции',
      text: 'Давайте добавим товары',
      attachTo: { element: '#depot', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 25,
      buttons: customButtons,
    },
    {
      id: 'opendepot',
      title: 'Товарные позиции',
      text: 'Давайте добавим товары',
      attachTo: { element: '#opendepot', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 25,
      buttons: customButtons,
    },
    {
      id: 'depotstock',
      title: 'Номенклатура',
      text: 'Это шаблон который создается один раз, в дальнейшем вы сможете добавлять товары в два клика',
      attachTo: { element: '#depotstock', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'openstock',
      title: 'Номенклатура',
      text: 'Это шаблон который создается один раз, в дальнейшем вы сможете добавлять товары в два клика',
      attachTo: { element: '', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 15,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'depotgroup',
      title: 'Группа',
      text: 'Задайте группу, чтоб товары на складе были упорядочены и их легко можно было найти',
      attachTo: { element: '#depotgroup', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'depotgroupsecond',
      title: 'Подгруппа',
      text: 'Бренд и артикулы помогут быстро искать, есть ли запчасть на складе',
      attachTo: { element: '#depotsubgroup', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'closedepotstock',
      attachTo: { element: '', on: 'bottom' },
    },
    {
      id: 'depotprice',
      title: 'Почём вы купили запчасть?',
      text: 'Вы можете заполнить одно из полей, второе рассчитается автоматически',
      attachTo: { element: '#depotprice', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'depotcount',
      title: 'Количество',
      text: 'Сколько добавить на склад?',
      attachTo: { element: '#depotcount', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 20, crossAxis: 0 })],
      },
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },

      modalOverlayOpeningRadius: 10,
      buttons: customButtons,
    },
    {
      id: 'savedepot',
      title: 'Добавляем на склад?',
      text: 'Нажмите кнопку Сохранить или можно добавить еще одну позицию',
      attachTo: { element: '#savedepot', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
      buttons: [
        {
          type: 'back',
          classes: styles.button_next,
          text: '◀◀',
        },
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
      ],
    },
    {
      id: 'submitdepot',
      title: 'Сохраняем',
      text: 'Нажмите, чтобы вернуться к заказ-наряду ',
      attachTo: { element: '#submitdepot', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      canClickTarget: true,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
    },

    // -----------------------------------------------------------------------------------------------------------------//
    // Этап 4 Редактирование Part II

    {
      id: 'returndetails',
      title: 'авправпрар',
      text: '',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
      buttons: [
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
      ],
    },
    {
      id: 'stockitem',
      title: [
        `<p>Теперь мы можем добавить запчасть</p>
        <p> в заказ-наряд</p>`,
      ],
      text: 'В дальнейшем запчасти будут добавлены на склад заранее, и во время создания заказ-наряда вы просто выбираете деталь из спиcка',
      attachTo: { element: '#stockitem', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
      buttons: [
        {
          classes: styles.button_next,
          type: 'cancel',
          text: '✖',
        },
        {
          classes: styles.button_next,
          type: 'next',
          text: '▶▶',
        },
      ],
    },
    {
      id: 'stockitemcount',
      title: 'Запчасти',
      text: 'При выборе товара, вы можете указать нужное количество',
      attachTo: { element: '#stockitemcount', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
      buttons: customButtons,
    },
    {
      id: 'stockopenmodal',
      title: 'Открытие модалки на добавление',
      text: '',
      attachTo: { element: '#stockopenmodal', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 5,

      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 25,
      buttons: customButtons,
    },
    {
      id: 'stockitemstock',
      title: 'И можете',
      text: 'Поменять цену продажи или указать скидку',
      attachTo: { element: '#stockitemstock', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 30,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 15,
      buttons: customButtons,
    },

    {
      id: 'stockclosemodal',
      title: 'close',
      text: '',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      buttons: customButtons,
    },
    {
      id: 'stockinfo',
      title: [`<p>Запчасть добавлена </p><p>в Заказ-наряд</>`],
      text: ' Теперь стоимость заказ-наряда изменена в соответствии с добавленными деталями',
      attachTo: { element: '', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      buttons: customButtons,
    },
    {
      id: 'repair-part-performers',
      title: 'Исполнитель',
      text: 'Тут так же можно указать исполнителей (с ролью Менеджер ОЗЧ), если у вас есть сотрудники, кто получает начисления за подбор запчастей',
      attachTo: { element: '#repairpartperformers', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 24,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 40, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 5,
      buttons: customButtons,
    },
    {
      id: 'timeposts',
      title: 'Время на постах',
      text: 'Можно забронировать пост, чтобы видеть загрузку когда к вам хочет записаться клиент',
      attachTo: { element: '#timeposts', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 24,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 35, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 5,
      buttons: customButtons,
    },
    {
      id: 'files',
      title: 'Добавьте файлы в свой заказ-наряд',
      text: 'Это могут быть фото, отсканированные документы или видео',
      attachTo: { element: '#files', on: 'top' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 24,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 35, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 5,
      buttons: customButtons,
    },
    {
      id: 'final',
      title: 'Черновик заказ-наряда создан',
      text: 'Когда начнете ремонт автомобиля, поменяйте статус заказ-наряда на В РАБОТЕ и распечатайте заказ-наряд',
      attachTo: { element: '#final', on: 'bottom' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 4,
      when: {
        show() {
          const currentStep = tour.getCurrentStep();
          currentStep && addProgressBar(currentStep, tour);
        },
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 35, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 5,
      buttons: customButtons,
    },
    {
      id: 'workbench',
      title: [
        `<p> ПОЗДРАВЛЯЕМ !!! </p>
       `,
      ],
      text: 'Вы познакомились с главным функционалом - научились создавать заказ-наряды',
      attachTo: { element: '', on: 'right' },
      scrollTo: { behavior: 'smooth', block: 'center' },
      classes: styles.frame,
      modalOverlayOpeningPadding: 15,
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 30, crossAxis: 0 })],
      },
      modalOverlayOpeningRadius: 5,
      buttons: [
        {
          classes: styles.button_end,
          type: 'cancel',
          text: 'Закрыть',
        },
      ],
    },
  ];
}
export default addSteps;
