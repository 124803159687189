import { useTranslation } from 'next-i18next';
import UserItem           from 'components/User/Item';
import UICircleButton     from 'components/UI/CircleButton';
import styles             from './finder-client-item.module.scss';
import common             from './../../finder.module.scss';

import NewRepairIcon      from '@material-ui/icons/PostAddOutlined';

function FinderClientItem ({ data, closeFinder }) {
  const { t } = useTranslation('common');
  
  return (
    <div className={styles.root}>
      <UserItem
        data={data}
        href={`/clients/details?id=${data.id}`}
        linkProps={{
          target: "_blank"
        }}
      />

      <div className={common.actions}>
        <UICircleButton
          icon={<NewRepairIcon/>}
          size="medium"
          positive
          route={`/repairs/new?clientId=${data.id}`}
          title={t('common.repair.titles.new')}
          onClick={closeFinder}
        />
      </div>
    </div>
  )
};

export default FinderClientItem;