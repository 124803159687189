import {
  memo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useTranslation } from 'next-i18next';
import {
  Input,
  Button,
} from 'semantic-ui-react';
import UIDeferred from 'components/UI/Deferred';
import UICircleButton from 'components/UI/CircleButton';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import cns from 'classnames';
import styles from './finder.module.scss';

import FinderDefaultItem from './Items/Default';
import FinderRepairItem from './Items/Repair';
import FinderVehicleItem from './Items/Vehicle';
import FinderCounterpartyItem from './Items/Counterparty';
import FinderClientItem from './Items/Client';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import {
  isLocalStorageAvailable,
  isProfileSubscribed,
} from 'helpers';
import Image from 'next/image';
import { useWindowSize } from 'hooks/useWindowSIze';
import { useProductEvents } from 'hooks';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const RESOURCE_ITEMS_MAP = {
  'repairs': FinderRepairItem,
  'vehicles': FinderVehicleItem,
  'workshopCounterparties': FinderCounterpartyItem,
  'clients': FinderClientItem,
};

function Finder({ loading, data, query, onChange, find, reset }) {
  const { t } = useTranslation('common');
  const [resultsVisible, setResultsVisible] = useState(false);
  const [filter, setFilter] = useState(null);

  const billAccount = useSelector(state => state.billAccount.data);

  // --------------------------------------------------------------

  const delayedFind = useCallback(debounce((q) => {
    find(q);

    if (!resultsVisible) {
      setResultsVisible(true);
    }
  }, 1000), [resultsVisible]);

  // --------------------------------------------------------------

  const isEnoughQuery = (value) => {
    return value.length >= 3;
  };

  // --------------------------------------------------------------

  const _onChange = (e, { value }) => {
    onChange(value);

    if (isEnoughQuery(value)) {
      delayedFind(value);
    }

    if (isEmpty(value)) {
      reset();
    }
  };

  // --------------------------------------------------------------

  const isEmptyResult = () => {
    let result = true;

    for (let resource in data) {
      if (!isEmpty(data[resource].items)) {
        result = false;
        break;
      }
    }

    return result;
  };

  // --------------------------------------------------------------

  useEffect(() => {
    if (!isEnoughQuery(query) && resultsVisible) {
      setResultsVisible(false);
    }
  }, [query]);

  // --------------------------------------------------------------

  const handleEsc = useCallback((event) => {
    if (event.keyCode === 27) {
      setResultsVisible(false);
    }
  }, []);

  // --------------------------------------------------------------

  useEffect(() => {
    const className = 'with-search-results';

    if (resultsVisible) {
      document.body.classList.add(className);
      window.addEventListener('keydown', handleEsc);
    } else {
      document.body.classList.remove(className);
      window.removeEventListener('keydown', handleEsc);
    }
  }, [resultsVisible]);

  // --------------------------------------------------------------

  const getResourcesKeys = () => {
    return isNull(filter) ? Object.keys(data) : [filter];
  };
  const router = useRouter();
  const getNotEmptyResourcesKeys = () => {
    return Object.keys(data).filter(item => !isEmpty(data[item].items));
  };

  const availableFilters = getNotEmptyResourcesKeys();





  const events = useProductEvents();


  return (
    <div className={styles.root}>

      <div className={styles.input_wrapper}>
        <div className={styles.icon}>
          <SearchIcon />
        </div>
        <div className={styles.input}>
          <Input
            fluid
            placeholder={t('common.finder.placeholder')}
            loading={loading}
            onChange={_onChange}
            onClick={() => {
              if (isEnoughQuery(query)) {
                setResultsVisible(true);
              }
            }}
            value={query}
          />
        </div>
        <If condition={!isEmpty(query)}>
          <div className={styles.clear}>
            <UICircleButton
              icon={<CloseIcon />}
              size="large"
              transparent
              onClick={() => {
                onChange();
                reset();
              }}
            />
          </div>
        </If>
      </div>



      <If condition={resultsVisible}>
        <div
          className={styles.overlay}
          onClick={() => {
            setResultsVisible(false);
          }}
        />

        <div className={styles.results}>
          <div className={styles.results_header}>
            <div className={styles.results_title}>
              {t('common.finder.results.title')}
            </div>

            <div className={styles.filters}>
              <If condition={!isEmpty(availableFilters)}>
                <div
                  className={cns(styles.filters_resource, {
                    [styles.active]: isNull(filter),
                  })}
                  onClick={() => {
                    setFilter(null);
                  }}
                >
                  {t('common.finder.results.all')}
                </div>
              </If>

              <For each="resource" of={availableFilters} index="index">
                <div
                  key={resource}
                  className={cns(styles.filters_resource, {
                    [styles.active]: filter === resource,
                  })}
                  onClick={() => {
                    setFilter(resource);
                  }}
                >
                  {t(`common.finder.resources.${resource}`)}
                </div>
              </For>
            </div>
          </div>

          <Choose>
            <When condition={isEmptyResult()}>
              <UIDeferred delay={1000}>
                <div className={styles.empty_results}>
                  {t('common.finder.results.empty1')}{' '}
                  &laquo;<strong>{query}</strong>&raquo;{' '}
                  {t('common.finder.results.empty2')}
                </div>
              </UIDeferred>
            </When>
            <Otherwise>
              <div className={styles.results_content}>
                <For each="resource" of={getResourcesKeys()} index="index">
                  <If condition={!isEmpty(data[resource].items)}>
                    <div
                      key={resource}
                      className={cns(styles.resource, styles[resource])}
                    >
                      <div className={styles.resource_title}>
                        {t(`common.finder.resources.${resource}`)}
                      </div>
                      <div className={cns(styles.resource_result, styles[resource])}>
                        <For each="item" of={data[resource].items} index="index">
                          <div
                            className={cns(styles.entity_item, styles[resource])}
                            key={item.id}
                          >
                            {(() => {
                              const ItemComponent = RESOURCE_ITEMS_MAP[resource] || FinderDefaultItem;

                              return (
                                <ItemComponent
                                  data={item}
                                  closeFinder={() => {
                                    setResultsVisible(false);
                                  }}
                                />
                              );
                            })()}
                          </div>
                        </For>
                      </div>
                    </div>
                  </If>
                </For>
              </div>
            </Otherwise>
          </Choose>
        </div>
      </If>
    </div>
  );
};

export default memo(Finder);