import { ROLES }  from 'helpers/constants';
import UserAccess from './Component';

export function isEmployee (user) {
  return user.type === 'employee';
};

export function isMaster (user) {
  return user.roles.includes(ROLES.master);
};

export function isDirector (user) {
  return user.roles.includes(ROLES.director);
};

export function isZapchastist (user) {
  return user.roles.includes(ROLES.zapchastist);
}

export function isCashier (user) {
  return user.roles.includes(ROLES.cashier);
}

export function isMechanic (user) {
  return user.roles.includes(ROLES.mechanic);
}

export default UserAccess;