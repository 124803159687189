import styles from './finder-default-item.module.scss';

function FinderDefaultItem ({ data }) {
  return (
    <div className={styles.root}>
      #{data.id}
    </div>
  )
};

export default FinderDefaultItem;