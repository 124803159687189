import { memo }     from 'react';
import PropTypes    from 'prop-types';
import Head         from 'next/head';
import SideMenu     from 'components/Navigation/SideMenu';
import Finder       from 'components/Navigation/Finder';
import TopPanel     from 'components/Navigation/TopPanel';
import FeatureModal from 'components/FeatureModal';
import cns          from 'classnames';
import { isProd }   from 'helpers/helpers'
import styles	      from './layout-employee.module.scss';
import common       from './../layout.module.scss';

const EmployeeLayout = ({ title, children }) => {
  const hideMenu = () => {
    document.body.classList.remove('menu-opened');
  };  

  return (
    <div 
      id="app-layout"
      className={cns(styles.component, common.layout)}
    >
      <If condition={isProd()}>
        <Head>
          <script type="text/javascript" src="/vendors/verbox.js"/>
        </Head>
      </If>

      <div className={common.top_panel}>
        <TopPanel title={title}/>
      </div>

      <div className={common.search}>
        <Finder/>
      </div>

      <div className={common.menu_overlay} onClick={hideMenu}/>
      <div className={common.menu}>
        <SideMenu/>
      </div>

      <div className={common.page}>
        {children}
      </div>

      <FeatureModal/>
    </div>
  );
};

EmployeeLayout.propTypes = {
  title: PropTypes.string.isRequired
};

export default memo(EmployeeLayout);