import SideMenuSection from './../../Section';
import SideMenuLeaf from './../../Leaf';
import UICircleButton from 'components/UI/CircleButton';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import ClientsIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import VehiclesIcon from '@material-ui/icons/DriveEtaOutlined';
import CounterpartiesIcon from '@material-ui/icons/WorkTwoTone';
import AddIcon from '@material-ui/icons/AddOutlined';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import useProductEvents from 'hooks/productEvents';
import {
  ROLES,
  UserAccess,
} from 'components/User';
import getSideMenuSection from '../Favourites/list';

const SideMenuCRMSection = ({ open, onToggle, sections, favourites, addFavourites, removeFavourites }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const events = useProductEvents();

  return (
    <SideMenuSection
      label="CRM"
      open={open}
      onToggle={onToggle}
    >
      <div>

        {
          sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }
      </div>
      {/* <SideMenuLeaf
        icon={<ClientsIcon />}
        text={t('employee.menu.clients')}
        route="/clients/list"
      /> */}
      {/* <SideMenuLeaf
        id='menuvehicle'
        icon={<VehiclesIcon />}
        text={t('employee.menu.vehicles')}
        route="/clients/vehicles"
      /> */}
      {/* <SideMenuLeaf
        id='closecounterparty'
        icon={<CounterpartiesIcon />}
        text={t('employee.menu.counterparty')}
        route="/clients/counterparties?tab=all"

        
      action={
        <UICircleButton
          icon={<AddIcon />}
          positive
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            events.sendTo(['amplitude'], 'click_add_counterparty_in_menu__workshop');
            router.push('/clients/counterparties/new?type=LEGAL_ENTITY');
          }}
        />
      }
      /> */}

      {/* <UserAccess roles={[ROLES.master, ROLES.director, ROLES.zapchastist, ROLES.cashier]}>
        <SideMenuLeaf
          icon={<PhoneCallbackIcon/>}
          text={t('employee.menu.calls')}
          route="/calls"
          onClick={() => {
            events.sendTo(['amplitude'], 'open_page__calls__workshop');
          }}
        />
      </UserAccess> */}
    </SideMenuSection>
  );
};

export default SideMenuCRMSection;