import { useRouter } from "next/router";
import React, { useContext, useEffect, useState } from "react";
import { ShepherdTourContext } from "react-shepherd";
import styles from './shepherd-components.module.scss';
import { useTour } from "../shepherd";
import ModalOpenTour from "./ModalOpenTour";
import { ROLES, UserAccess } from "components/User";

export default function ButtonShepheld() {
  const tour = useTour();
  const [open, setOpenModal]=useState(false);
const[renewal, setRenewal]=useState(false);
  const router = useRouter();
  useEffect(() => {
    if( router.query.auth){
      setOpenModal(true);
    }
    if((router.query.id&&router.query.tour) ||(router.query.tour&& router.query.depot) ||(router.query.tour&& router.query.backTo)){
      setRenewal(true)
    
    }
  },[])
  useEffect(()=>{
    if(tour?.currentStep?.id==='workbench'){
      setRenewal(false)
    }
  },[tour?.currentStep?.id])
  return (
    <UserAccess roles={[ROLES.director, ROLES.master]}>
    <div className={styles.flex}>

    <button
className={styles.buttonstart}
      onClick={() => {
        //редактирование I
      if(router.query.id&&router.query.tour&&!router.query.depot){
        tour.start()
        tour.show(12,true)
        events.sendTo(['amplitude'], 'onboarding.creating-repair-to-draft-repair.workshop')
      }
      // приемка
      else if(router.query.tour&& router.query.backTo){
        tour.start()
        tour.show(33,true)
        tour.next()
      }
      //редактирование II
      else if(router.query.tour&& router.query.depot){
        tour.start()
        tour.show(54, true)
      }
      else setOpenModal(true);
      }}
      >
        {renewal?'Возобновить интерактивное обучение' :'Интерактивное обучение'

        }
    
    </button>
    <ModalOpenTour
    open={open}
    onClose={() => setOpenModal(false)}/>
      </div>
      </UserAccess>
  );
}
// (tour && !depot) shepherdTour.show(12, true) При переходе от создания
//  if (tour) tourShepherd.show(31, true) Возобновление тура при переходе на приемку
//  if (depot) tour.show(49, true) Возобновление при возвращении со страницы приемки