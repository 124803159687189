import { useTranslation } from 'next-i18next';
import SideMenuSection from './../../Section';
import SideMenuLeaf from './../../Leaf';
import {
  ROLES,
  UserAccess,
} from 'components/User';

import WorkshopIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import EmployeeIcon from '@material-ui/icons/AccountBoxOutlined';

import PostsIcon from '@material-ui/icons/BuildOutlined';
import IntegrationsIcon from '@material-ui/icons/ExtensionTwoTone';
import WidgetsIcon from '@material-ui/icons/Widgets';
import { useProductEvents } from 'hooks';
import getSideMenuSection from '../Favourites/list';

const SideMenuSettingsSection = ({ open, onToggle, sections, favourites, addFavourites, removeFavourites }) => {
  const { t } = useTranslation('common');
  const events = useProductEvents();

  return (
    <SideMenuSection
      label={t('employee.menu.settings')}
      open={open}
      onToggle={onToggle}
    >
      <div>

        {
          sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }
      </div>
      {/* <UserAccess roles={[ROLES.director]}>
        <SideMenuLeaf
          icon={<WorkshopIcon />}
          route="/settings/garage/edit"
          text={t('employee.menu.myGarage')}
        />
        <SideMenuLeaf
          id='sideclient'
          icon={<EmployeeIcon />}
          route="/settings/employees"
          text={t('employee.menu.employees')}
        />
      </UserAccess> */}

      {/* < UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          icon={<PostsIcon />}
          route="/settings/garage/posts"
          text={t('employee.menu.garagePosts')}
        />
      </UserAccess> */}

      {/* < UserAccess roles={[ROLES.director, ROLES.cashier]}>
        <SideMenuLeaf
          icon={<IntegrationsIcon />}
          route="/settings/integrations"
          text={'Касса'}
          onClick={() => events.sendTo(['amplitude'], 'open-integrations-section_workshop')}
        />
      </UserAccess> */}

      {/* < UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          // isNew={true}
          icon={<WidgetsIcon />}
          route="/settings/booking_widget"
          text="Виджет записи"
        />
      </UserAccess> */}
    </SideMenuSection>
  );
};

export default SideMenuSettingsSection;