import SideMenuLeaf              from '../../Leaf';
import WidgetsOutlined           from '@material-ui/icons/WidgetsOutlined';
import AddIcon                   from '@material-ui/icons/Add';
import ClientsIcon               from '@material-ui/icons/SupervisedUserCircleOutlined';
import BookingIcon               from '@material-ui/icons/BookOutlined';
import PostLoadingIcon           from '@material-ui/icons/HourglassEmptyOutlined';
import VehiclesIcon              from '@material-ui/icons/DriveEtaOutlined';
import { useTranslation }        from 'next-i18next';
import ClosedFolderIcon          from '@material-ui/icons/FolderOpenTwoTone';
import AppealsIcon               from '@material-ui/icons/MoveToInboxOutlined';
import StarHalfRoundedIcon       from '@material-ui/icons/StarHalfRounded';
import RvHookupOutlined          from '@material-ui/icons/RvHookupOutlined';
import CounterpartiesIcon        from '@material-ui/icons/WorkTwoTone';
import ShippingIcon              from '@material-ui/icons/LocalShippingOutlined';
import Inbox                     from '@material-ui/icons/Inbox';
import ListAltOutlinedIcon       from '@material-ui/icons/ListAltOutlined';
import {
  CategoryOutlined,
  DnsOutlined,
  HistoryOutlined,

}                                from '@material-ui/icons';
import WorkshopIcon              from '@material-ui/icons/StoreMallDirectoryOutlined';
import EmployeeIcon              from '@material-ui/icons/AccountBoxOutlined';
import PersonalVideoOutlinedIcon from '@material-ui/icons/PersonalVideoOutlined';
import PostsIcon                 from '@material-ui/icons/BuildOutlined';
import IntegrationsIcon          from '@material-ui/icons/ExtensionTwoTone';
import WidgetsIcon               from '@material-ui/icons/Widgets';
import SupportIcon               from '@material-ui/icons/QuestionAnswerOutlined';
import TelegramIcon              from '@material-ui/icons/Telegram';
import IdeaIcon                  from '@material-ui/icons/EmojiObjectsOutlined';
import EditNote                  from '@material-ui/icons/Edit';
import FmdBadIcon                from '@mui/icons-material/FmdBad';
import {
  UserAccess,
  ROLES,
}                                from 'components/User';
import AnalyticsIcon             from '@material-ui/icons/PollOutlined';
import NextWeekOutlined          from '@material-ui/icons/NextWeekOutlined';
import NoteAddOutlined           from '@material-ui/icons/NoteAddOutlined';
import DashboardOutlinedIcon     from '@material-ui/icons/DashboardOutlined';
import { useProductEvents }      from '../../../../../hooks';

const getSideMenuSection = (id, favourite) => {
  // const { t } = useTranslation('common');

  const events = useProductEvents();
  function getDateForQuery() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return `${year}-${month + 1}-${day}`;
  }

  const dateQuery = getDateForQuery();
  const list = [
    {
      name: 'Дашборд',
      component: <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<DashboardOutlinedIcon/>}
          id="workbench"
          text={'Дашборд'}
          route={'/dashboard'}
          onClick={() => {
            events.send('open_workbench_desk_mp_workshop', {type: 'click'});
          }}
        />
      </UserAccess>,
    },
    {
      name: 'Рабочий стол',
      component: <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<PersonalVideoOutlinedIcon/>}
          id="workbench"
          text={'Рабочий стол'}
          route={'/workbench'}
          onClick={() => {
            events.send('open_workbench_desk_mp_workshop', {type: 'click'});
          }}
        />
      </UserAccess>,
    },
    {
      name: 'Аналитика',
      component: <UserAccess roles={[ROLES.director, ROLES.master, ROLES.zapchastist]}>
        <SideMenuLeaf
          favourite={true}
          icon={<AnalyticsIcon/>}
          text={'Аналитика'}
          route="/analytics"
        />
      </UserAccess>,
    },
    {
      name: 'Создать заказ-наряд',
      component: <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<NextWeekOutlined/>}
          text={'Создать заказ-наряд'}
          route="/repairs/new"
          onClick={() => {
            events.send('menu.create.order-click.workshop', {type: 'click'});
          }}
        />
      </UserAccess>,
    },
    {
      name: 'Записать клиента',
      component: <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<NoteAddOutlined/>}
          text={'Записать клиента'}
          route={`/planning/arrivals/date/${dateQuery}?role=ALL&open=true`}
          onClick={() => {
            events.send('menu.customer.record-click.workshop', {type: 'click'});
          }}
        />


      </UserAccess>,
    },
    //----------------------------------------------------------------------------//
    {
      name: 'Запись клиентов',
      component: <SideMenuLeaf
        icon={<BookingIcon/>}
        {...favourite}

        text={'Запись клиентов'}
        route={`/planning/arrivals/month/${new Date().getMonth()}`}
      />,

    },
    {
      name: 'Загрузка постов',
      component:
        <SideMenuLeaf
          {...favourite}

          icon={<PostLoadingIcon/>}
          text={'Загрузка постов'}
          route="/planning/posts"
        />,
    },
    //----------------------------------------------------------------------------//
    {
      name: 'Заказ-наряды',
      component: <SideMenuLeaf
        {...favourite}
        icon={<ClosedFolderIcon/>}
        text={'Заказ-наряды'}
        id="menuzn"
        route="/repairs?tab=all"/>,
    },
    {
      name: 'Заявки на ремонт',
      component:
        <UserAccess roles={[ROLES.director, ROLES.master, ROLES.zapchastist]}><SideMenuLeaf
          {...favourite}
          icon={<AppealsIcon/>}
          text={'Заявки на ремонт'}
          route="/appeals"
        />
        </UserAccess>,
    },
    {
      name: 'Отзывы об СТО',
      component: <SideMenuLeaf
        {...favourite}
        icon={<StarHalfRoundedIcon/>}
        text={'Отзывы об СТО'}
        route="/repairs/reviews"
        onClick={() => {
          events.send('menu.rewies-click.workshop');
        }}
      />,
    },
    {
      name: 'Вызвать эвакуатор',
      component: <SideMenuLeaf
        {...favourite}

        icon={<RvHookupOutlined/>}
        text={'Вызвать эвакуатор'}
        route={'/evacuator'}
        onClick={() => {
          events.send('click_call_evacuator_workshop');
        }}
      />,
    },
    //----------------------------------------------------------------------------//
    {
      name: 'Клиенты',
      component: <SideMenuLeaf
        icon={<ClientsIcon/>}
        {...favourite}

        text={'Клиенты'}
        route="/clients/list"
      />,
    },
    {
      name: 'Автомобили',
      component: <SideMenuLeaf
        id="menuvehicle"
        icon={<VehiclesIcon/>}
        {...favourite}


        text={'Автомобили'}
        route="/clients/vehicles"
      />,
    },
    {
      name: 'Контрагенты',
      component: <SideMenuLeaf
        {...favourite}

        id="closecounterparty"
        icon={<CounterpartiesIcon/>}
        text={'Контрагенты'}
        route="/clients/counterparties?tab=all"
      />,
    },
    //----------------------------------------------------------------------------//
    {
      name: 'Принять товар',
      component:
        <SideMenuLeaf

          {...favourite}
          icon={<ShippingIcon/>}
          text={'Принять товар'}
          route="/stock/operations/receive"
        />,


    },
    {
      name: 'Склады',
      component: <SideMenuLeaf
        {...favourite}

        icon={<DnsOutlined/>}
        text={'Склады'}
        route="/stock"
      />,
    },
    {
      name: 'Товары',
      component: <SideMenuLeaf
        {...favourite}

        icon={<Inbox/>}
        text={'Товары'}
        route="/stock/products?tab=available"
      />,
    },
    {
      name: 'Поставщики',
      component: <SideMenuLeaf
        {...favourite}
        icon={<ListAltOutlinedIcon/>}
        route="/settings/suppliers_parts"
        text="Поставщики"
      />,


    },
    {
      name: 'Номенклатуры',
      component: <SideMenuLeaf
        icon={<CategoryOutlined/>}
        {...favourite}
        text={'Номенклатуры'}
        route="/stock/stocks"
      />,
    },
    {
      name: 'Заказ запчастей',
      component: <SideMenuLeaf
        icon={<AddIcon/>}
        {...favourite}
        text={'Заказ запчастей'}
        route="/stock/order-parts?tab=ordered"
        onClick={() => {
          events.send('menu.spare.parts.order-click.workshop', {type: 'click'});
        }}
      />,
    },
    //----------------------------------------------------------------------------//
    {
      name: 'Автосервис',
      component:
        <UserAccess roles={[ROLES.director]}><SideMenuLeaf
          icon={<WorkshopIcon/>}
          {...favourite}
          route="/settings/garage/edit"
          text={'Автосервис'}
        />
        </UserAccess>,
    },

    {
      name: 'Сотрудники',
      component:
        <UserAccess roles={[ROLES.director]}>
          <SideMenuLeaf
            id="sideclient"
            icon={<EmployeeIcon/>}
            {...favourite}
            route="/settings/employees"
            text={'Сотрудники'}
          />
        </UserAccess>,
    },
    {
      name: 'Посты ремонта',
      component:
        < UserAccess roles={[ROLES.director, ROLES.master]}> <SideMenuLeaf
          icon={<PostsIcon/>}
          {...favourite}
          route="/settings/garage/posts"
          text={'Посты ремонта'}
        />
        </UserAccess>,
    },
    {
      name: 'Касса',
      component:
        < UserAccess roles={[ROLES.director, ROLES.cashier]}><SideMenuLeaf
          icon={<IntegrationsIcon/>}
          route="/settings/integrations"
          {...favourite}
          text={'Касса'}
          onClick={() => events.sendTo(['amplitude'], 'open-integrations-section_workshop')}
        />
        </UserAccess>,
    },
    {
      name: 'Виджет записи',
      component:
        < UserAccess roles={[ROLES.director, ROLES.master]}><SideMenuLeaf
          icon={<WidgetsIcon/>}
          {...favourite}
          route="/settings/booking_widget"
          text="Виджет записи"
        />
        </UserAccess>,
    },
    //----------------------------------------------------------------------------//
    {
      name: 'Интерактивное обучение',
      component: <UserAccess roles={[ROLES.director, ROLES.master]}> <SideMenuLeaf
        {...favourite}
        onClick={() => {
          events.send(['amplitude'], 'onboarding.start-help.workshop');
        }}
        icon={<FmdBadIcon/>}
        text="Интерактивное обучение"
        route="/repairs/new?tour=true"
      /></UserAccess>,
    },
    {
      name: 'Техподдержка',
      component: <SideMenuLeaf
        onClick={() => {
          events.send('support_open_workshop');
        }}
        icon={<SupportIcon/>}
        {...favourite}
        text={'Техподдержка'}
        route="/support"
      />,
    },
    {
      name: 'Мы в Telegram',
      component: <SideMenuLeaf
        asSimpleLink
        {...favourite}
        icon={<TelegramIcon/>}
        text={'Мы в Telegram'}
        route="https://t.me/workshop_zencar"
      />,
    },
    {
      name: 'Пожаловаться',
      component: <SideMenuLeaf
        icon={<EditNote/>}
        {...favourite}
        text={'Пожаловаться'}
        route="/suggest-improvement?complaint=true"
        onClick={() => {
          events.sendTo(['amplitude'], 'menu.complaint-click.workshop');
        }}
      />,
    },
    {
      name: 'Хочу больше функционала',
      component: <SideMenuLeaf
        icon={<IdeaIcon/>}
        {...favourite}
        text={'Хочу больше функционала'}
        route="/suggest-improvement"
        onClick={() => {
          events.sendTo(['amplitude'], 'menu.more.functional-click.workshop');
        }}
      />,
    },
  ];


  return list.map((item) => {
    if (item.name === id) {
      return item.component;
    }
  });

};
export default getSideMenuSection;

