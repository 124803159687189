import VehiclePlateHistory from './History';
import VehiclePlateItem    from './HistoryItem';
import VehiclePlate        from './Component';

export {
  VehiclePlate,
  VehiclePlateHistory,
  VehiclePlateItem
}

export default VehiclePlate;