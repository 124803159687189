import PropTypes          from 'prop-types';
import { useTranslation } from 'next-i18next';
import Link               from 'next/link';
import UserStory          from 'components/User/Story';
import styles             from './counterparty-item.module.scss';

const CounterpartyItem = ({ data, asLink }) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <UserStory
          size="small"
          image={undefined}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.name}>
          <Choose>
            <When condition={asLink}>
              <Link href={`/clients/counterparties/details?id=${data.id}`}>
                <a>{data.name}</a>
              </Link>
            </When>
            <Otherwise>
              {data.name}
            </Otherwise>
          </Choose>
        </div>
        <div className={styles.param}>
          <div className={styles.label}>
            {t('common.counterparty.inn')}:{' '}
          </div>
          <div className={styles.value}>
            {data.inn}
          </div>
        </div>
      </div>
      <div className={styles.type}>
        {t(`common.counterparty.types.${data.type}`)}
      </div>
    </div>
  )
};

CounterpartyItem.propTypes = {
  data:   PropTypes.object.isRequired,
  asLink: PropTypes.bool
};

CounterpartyItem.defaultProps = {
  asLink: false
};

export default CounterpartyItem;