import { memo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'next-i18next'
import styles from './ui-list.module.scss';

const UIList = ({
  data, itemComponent, itemComponentProps, emptyText
}) => {
  const { t } = useTranslation('common');
  const ItemComponent = itemComponent;
  return (
    <div className={styles.component}>
      <Choose>
        <When condition={!isEmpty(data)}>
          <For each="item" of={data} index="index">
            <ItemComponent
              key={item.id || `index_${index}`}
              index={index}
              data={item}
              dataLength={data.length}
              {...itemComponentProps}
            />
          </For>
        </When>
        <Otherwise>
          <div className={styles.empty}>
            {!isEmpty(emptyText) ? emptyText : t('UI.SmartList.emptyData')}
          </div>
        </Otherwise>
      </Choose>
    </div>
  )
};

UIList.propTypes = {
  data: PropTypes.array,
  itemComponent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  itemComponentProps: PropTypes.object,
  emptyText: PropTypes.string
};

UIList.defaultProps = {
  data: [],
  itemComponentProps: {}
};

export default memo(UIList);