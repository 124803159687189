import SideMenuSection from 'components/Navigation/SideMenu/Section';
import SideMenuLeaf from 'components/Navigation/SideMenu/Leaf';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useProductEvents, useUser } from 'hooks';
import UICircleButton from 'components/UI/CircleButton';
import {
  ROLES,
  UserAccess,
} from 'components/User';
import RvHookupOutlined from '@material-ui/icons/RvHookupOutlined'
import ClosedFolderIcon from '@material-ui/icons/FolderOpenTwoTone';
import AppealsIcon from '@material-ui/icons/MoveToInboxOutlined';
import AddIcon from '@material-ui/icons/AddOutlined';
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useRef,
} from 'react';
import Api from 'api';
import isEmpty from 'lodash/isEmpty';

import styles from './repair-module.module.scss';
import { translit } from 'helpers';
import { loadMenuFavourites } from 'reducers/app';
import getSideMenuSection from '../Favourites/list';

const SideMenuRepairSection = ({ open, onToggle, favourites, removeFavourites, addFavourites, sections }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const user = useUser();
  const dispatch = useDispatch()
  const intervalId = useRef(null);
  const events = useProductEvents();
  useEffect(() => {
    return () => {
      clearInterval(intervalId.current);
    };
  }, []);


  return (
    <SideMenuSection
      label={t('employee.menu.repairs')}
      open={open}
      onToggle={onToggle}
    >
      <div>

        {
          sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }
      </div>
      {/* <SideMenuLeaf
        icon={<ClosedFolderIcon />}
        text={t('common.repair.many')}
        id='menuzn'
        route="/repairs?tab=all"
        favourite={favourites.includes('Заказ-наряды')}
        addFavourites={addFavourites}
        removeFavourites={removeFavourites}
      {...(() => {
        if (favourites.includes('Заказ-наряды')) {
          return {
            action: (
              // <button className={styles.favourite}
              <UICircleButton
                icon={<StarIcon />}
                // positive
                opacity
                className={styles.favourite}
                transparent={true}
                size="small"
                onClick={(e) => {
                  console.log('sgfsgdsg', e)
                  e.stopPropagation();
                  removeFavourites('Заказ-наряды')
                  // router.push('/repairs/new');

                }}
              />
            ),
          };
        }
        else {
          return {
            action: (
              <button className={styles.favourite}
                // icon={<StarBorderIcon />}
                // positive
                // // className={styles.favourite}
                // size="medium"
                onClick={(e) => {
                  e.stopPropagation();
                  addFavourites('Заказ-наряды')
                  // router.push('/repairs/new');

                }}
              />
            ),
          }

        }
      })()}
      /> */}
      {/* 
      <UserAccess roles={[ROLES.director, ROLES.master, ROLES.zapchastist]}>
        <SideMenuLeaf
          icon={<AppealsIcon />}
          text={t('employee.menu.appeals')}
          route="/appeals"
          favourite={favourites.includes('Заявки на ремонт')}
          addFavourites={addFavourites}
          removeFavourites={removeFavourites}
        />
      </UserAccess> */}

      {/* <SideMenuLeaf
        icon={<StarHalfRoundedIcon />}
        text={t('employee.menu.reviews')}
        route="/repairs/reviews"
        favourite={favourites.includes('Отзывы об СТО')}
        addFavourites={addFavourites}
        removeFavourites={removeFavourites}
        onClick={() => {
          events.send('menu.rewies-click.workshop')
        }}
      /> */}
      {/* <SideMenuLeaf
        icon={<RvHookupOutlined />}
        text={'Вызвать эвакуатор'}
        route={'/evacuator'}
        favourite={favourites.includes('Вызвать эвакуатор')}
        addFavourites={addFavourites}
        removeFavourites={removeFavourites}
        onClick={() => {
          events.send('click_call_evacuator_workshop')
        }}
      /> */}
    </SideMenuSection>
  );
};

export default SideMenuRepairSection;