import { useSelector, useDispatch } from 'react-redux';
import RepairItemParams             from './Component';
import API                          from 'api';

function ConnectedRepairItemParams (props) {
  const employees = useSelector(state => state.employees.data.items || []);
  const dispatch = useDispatch();

  const changeStatus = (id, status) => {
    return dispatch(API.mutations.changeRepairStatus({input: {id, status}}));
  };

  const changePerformer = (repairId, performerId) => {
    return dispatch(API.mutations.updateRepair({ input: {
      id: repairId,
      performerId
    }}));
  };

  return (
    <RepairItemParams
      {...props}
      employees={employees}
      changeStatus={changeStatus}
      changePerformer={changePerformer}
    />
  )
};

export default ConnectedRepairItemParams;