import { useProductEvents } from 'hooks';
import { useRouter } from 'next/router';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { useTour } from '../shepherd';
import styles from './shepherd-components.module.scss'

export default function OpenTour({onClose}) {
    const tour=useTour()
    const router=useRouter()
    const events = useProductEvents();
  return (
    <div className={styles.tour}>
      <p>Я - программа Зенкар, буду вашим помощником в управлении автосервисом.</p>
      <p>Давайте я расскажу вам, как просто формировать заказ-наряды.</p>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={() => onClose()}>В другой раз</button>
        <button className={styles.second} onClick={()=>{
            if(router.pathname!='/repairs/new'){
                router.push('/repairs/new?tour=true')
            }else {tour.start()}
            events.sendTo(['amplitude'], 'onboarding.start.workshop')
            onClose()
        }}>Начать</button>
       
      </div>
    </div>
  );
}
