import { 
  useState,
  useCallback,
  memo
}                         from 'react';
import PropTypes          from 'prop-types';
import { Form }           from 'formsy-semantic-ui-react';
import { Label, Button }  from 'semantic-ui-react';
import { useTranslation } from 'next-i18next';
import styles             from './depot-form.module.scss';

const label = <Label color="red" pointing />;

const getEmptyModel = () => ({
  name: "",
  description: ""
});

const DepotForm = ({ depot, data, submit }) => {
  const { t } = useTranslation('common');
  const [ valid, setValid ] = useState(false);
  const [ model, setModel ] = useState(Object.assign(getEmptyModel(), data));
  const action = model.id ? 'save' : 'create';

  const onChange = useCallback((field, value) => {
    const _model = Object.assign({}, model);

    _model[field] = value;

    setModel(_model);
  });

  return (
    <div className={styles.component}>
      <Form
        onInvalid={() => { setValid(false) }}
        onValid={() => { setValid(true) }}
        onValidSubmit={submit}
      >
        <If condition={model.id}>
          <Form.Input className="hidden-input" type="hidden" name="id" value={model.id}/>            
        </If>

        <Form.Input
          required
          autoComplete="off"
          name="name"
          label={t('common.depot.name')}
          validationErrors={{
            isDefaultRequiredValue: t('formErrors.required')
          }}
          value={model.name}
          onChange={(event, { value }) => { onChange('name', value) }}
          errorLabel={ label }
        />

        <Form.Input
          required
          autoComplete="off"
          name="description"
          label={t('common.depot.description')}
          validationErrors={{
            isDefaultRequiredValue: t('formErrors.required')
          }}
          value={model.description}
          onChange={(event, { value }) => { onChange('description', value) }}
          errorLabel={ label }
        />

        <Button
          color="teal"
          fluid
          size="tiny"
          disabled={!valid || depot.loading}
        >
          <Choose>
            <When condition={depot.loading}>
              {t('common.wait')}
            </When>
            <Otherwise>
              {t(`common.actions.${action}`)}
            </Otherwise>
          </Choose>
        </Button>
      </Form>
    </div>
  )
};

DepotForm.propTypes = {
  depot:  PropTypes.object.isRequired,
  data:   PropTypes.object,
  submit: PropTypes.func.isRequired
};

DepotForm.defaultProps = {
  submit: () => {}
};

export default memo(DepotForm);