import PropTypes   from 'prop-types';
import { useUser } from 'hooks';
import isEmpty     from 'lodash/isEmpty';

const UserAccess = ({ roles, andCondition, children }) => {
  const user = useUser();
  const allowedRoles = user.roles.filter(role => roles.includes(role));
  const canUserSeeCmp = isEmpty(roles) || !isEmpty(allowedRoles);

  return (
    <If condition={canUserSeeCmp && andCondition}>
      {children}
    </If>
  )
};

UserAccess.propTypes = {
  roles:        PropTypes.array,
  andCondition: PropTypes.bool
};

UserAccess.defaultProps = {
  roles: [],
  andCondition: true
};

export default UserAccess;