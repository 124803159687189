import UIModalClose from 'components/UI/ModalClose';
import React, { useCallback } from 'react';
import { Modal } from 'semantic-ui-react';
import OpenTour from './OpenTour';
import styles from './shepherd-components.module.scss';

export default function ModalOpenTour({open,onClose}) {
  const getConfig = useCallback(() => {
    const conf = {
      className: styles.component,
      
      // closeIcon: <UIModalClose onClick={onClose}/>,
      size: "small",
      open,
      onClose,
      closeOnEscape: true,
      closeOnDimmerClick: true
    }
    return conf;
  });

  return (
    <Modal {...getConfig()}>
    <Modal.Header className={styles.component}>
      <div>

    Добро пожаловать!
  
      </div>
    </Modal.Header>
    <Modal.Content className={styles.component}>
     <OpenTour onClose={onClose}/>
    </Modal.Content>
  </Modal>
  );
}
