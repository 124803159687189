import {
  memo,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'next-i18next';
import {
  ROLES,
  UserAccess,
} from 'components/User';
import GarageBalance from './GarageBalance';
import UserProfile from './Profile';
import SideMenuLeaf from './Leaf';
import SideMenuHeader from './Header';
import SideMenuPlanningSection from './Modules/Planning';
import SideMenuRepairSection from './Modules/Repair';
import SideMenuCRMSection from './Modules/CRM';
import SideMenuDepotSection from './Modules/Depot';
import SideMenuSettingsSection from './Modules/Settings';
import SideMenuHelpSection from './Modules/Help';
import debounce from 'lodash/debounce';
import isNull from 'lodash/isNull';
import cns from 'classnames';
import { useProductEvents, useUser } from 'hooks';
import styles from './side-menu.module.scss';

import AnalyticsIcon from '@material-ui/icons/PollOutlined';
import IdeaIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PersonalVideoOutlinedIcon from '@material-ui/icons/PersonalVideoOutlined';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import RvHookupOutlined from '@material-ui/icons/RvHookupOutlined'
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { AddIcCallOutlined } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import WidgetsOutlined from '@material-ui/icons/WidgetsOutlined';
import BuildTwoTone from '@material-ui/icons/BuildTwoTone';
import NextWeekOutlined from '@material-ui/icons/NextWeekOutlined';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined';
import ButtonShepheld from 'components/Shepherd/components/ButtonShepheld';
import { useTour } from 'components/Shepherd/shepherd';
import { useState } from 'react';
import SideMenuFavouritesSection from './Modules/Favourites';
import { isLocalStorageAvailable, translit } from 'helpers';
import { loadMenuFavourites } from 'reducers/app';
import UINotifications from 'components/UI/Notifications';

const SideMenu = ({
  sections, menuScrollPosition, toggleAppMenuSectionState,
  setAppMenuScrollPosition, method,
}) => {
  const { t } = useTranslation('common');
  const scroll = useRef(null);
  const user = useUser();
  let favouritesFromRedux = useSelector(state => state.app.favourites)
  const events = useProductEvents();
  const tour = useTour()
  const dispatch = useDispatch()
  const [favourites, setFavourites] = useState([])
  useEffect(() => {
    if (isLocalStorageAvailable()) {// проверяем готов ли localStorage
      const favouritesList = localStorage.getItem('FavouritesMenuSection');
      if (favouritesList == null) { // если готов, но там пусто, инициируем значение
        localStorage.setItem('FavouritesMenuSection', JSON.stringify(['Виджет записи', 'Заказ запчастей']))
        setFavourites(['Виджет записи', 'Заказ запчастей'])
        dispatch(loadMenuFavourites(['Виджет записи', 'Заказ запчастей']))
        // setFavourites(['Виджет записи', 'Заказ запчастей'])
      } else {// если есть значение в localStorage
        const favouritesListMenu = JSON.parse(favouritesList)// пытаемся взять значение из localStorage
        dispatch(loadMenuFavourites(favouritesListMenu))// синхронизируем его с редуксом
        // console.log(typeof favouritesListMenu)
        setFavourites(favouritesListMenu)
      }
    }
    scroll.current.scrollTop = menuScrollPosition;
  }, []);
  useEffect(() => {
    if (favouritesFromRedux) {

      setFavourites(favouritesFromRedux)
    }
  }, [favouritesFromRedux])
  const pathname = useRouter();
  //TODO: добавить дополнение до двух знаков

  const removeFavourites = (text) => {
    setFavourites((prevState) => {
      console.log('target', text)
      console.log('PrevState', prevState)
      const newArr = [...prevState]
      const removeIndex = newArr.findIndex((item) => item === text)
      newArr.splice(removeIndex, 1)
      console.log('removeFavourites', newArr)
      localStorage.setItem('FavouritesMenuSection', JSON.stringify(newArr))
      dispatch(loadMenuFavourites(newArr))
      UINotifications.show({
        type: 'success',
        html: `Раздел "${text}" удален из Избранного`
      });
      return newArr
    })
    events.send(`favourites.remove-${translit(text)}.workshop`)
  }

  const addFavourites = (text) => {
    setFavourites((prevState) => {
      console.log('PrevState', prevState)
      const newArr = [...prevState]
      console.log('addFavourites', newArr.length)
      const masterAndDirector = (newArr.length >= 6 && user.oneOfRole([ROLES.director, ROLES.master]))
      const zapcastist = ((newArr.length > 9 && user.oneOfRole([ROLES.zapchastist])))
      const cashierAndMechanic = ((newArr.length > 10 && user.oneOfRole([ROLES.cashier, ROLES.mechanic])))
      // console.log('ROLE', user.oneOfRole([ROLES.mechanic]))
      console.log(newArr.length)
      if (masterAndDirector || zapcastist || cashierAndMechanic) {
        UINotifications.show({
          type: 'danger',
          html: 'Нет места в Избранном'
        });
        return newArr
      } else {

        newArr.push(text)
        localStorage.setItem('FavouritesMenuSection', JSON.stringify(newArr))
        dispatch(loadMenuFavourites(newArr))
        UINotifications.show({
          type: 'success',
          html: `Раздел "${text}" добавлен в Избранное`
        });
        return newArr
      }
    })
    events.send(`favourites.add-${translit(text)}.workshop`)
  }
  return (
    <div className={cns(styles.root, {
      [styles.danger]: method === 'AUTHENTICATE_BY_ADMIN',
    })}>
      <SideMenuHeader />

      <div
        ref={scroll}
        className={styles.scroll}
        onScroll={debounce(() => {
          if (!isNull(scroll.current)) {
            setAppMenuScrollPosition(scroll.current.scrollTop);
          }
        }, 500)}
      >
        <div className={styles.scroll_content}>
          <div className={styles.nav}>
            <GarageBalance />
            <SideMenuFavouritesSection
              favourites={favourites}
              removeFavourites={removeFavourites}
              addFavourites={addFavourites}
              sections={['Дашборд', 'Рабочий стол', "Аналитика", "Создать заказ-наряд", "Записать клиента"]}
              open={sections.favourotes}
              onToggle={() => {
                toggleAppMenuSectionState('favourotes');
              }} />

            <UserAccess roles={[ROLES.director, ROLES.master, ROLES.mechanic, ROLES.zapchastist]}>
              <SideMenuPlanningSection
                open={sections.planning}
                favourites={favourites}
                removeFavourites={removeFavourites}
                addFavourites={addFavourites}
                onToggle={() => {
                  toggleAppMenuSectionState('planning');
                }}
                sections={['Запись клиентов', 'Загрузка постов']}
              />

            </UserAccess>

            <SideMenuRepairSection
              favourites={favourites}
              removeFavourites={removeFavourites}
              addFavourites={addFavourites}
              sections={['Заказ-наряды', "Заявки на ремонт", "Отзывы об СТО", "Вызвать эвакуатор"]}
              open={sections.repairs || tour?.isActive}
              onToggle={() => {
                toggleAppMenuSectionState('repairs');
              }}
            />
            <UserAccess roles={[ROLES.director, ROLES.master, ROLES.zapchastist]}>
              <SideMenuCRMSection
                favourites={favourites}
                removeFavourites={removeFavourites}
                addFavourites={addFavourites}
                open={sections.crm || tour?.isActive}
                onToggle={() => {
                  toggleAppMenuSectionState('crm');
                }}
                sections={['Клиенты', "Автомобили", "Контрагенты"]}
              />
            </UserAccess>

            <UserAccess roles={[ROLES.director, ROLES.zapchastist]}>
              <SideMenuDepotSection
                favourites={favourites}
                removeFavourites={removeFavourites}
                addFavourites={addFavourites}
                open={sections.depot}
                sections={['Принять товар', "Склады", "Товары", "Поставщики", "Номенклатуры", "Заказ запчастей"]}
                onToggle={() => {
                  toggleAppMenuSectionState('depot');
                }}
              />
            </UserAccess>

            <SideMenuSettingsSection
              favourites={favourites}
              removeFavourites={removeFavourites}
              addFavourites={addFavourites}
              open={sections.settings || tour?.isActive}
              sections={['Автосервис', "Сотрудники", "Посты ремонта", "Касса", "Виджет записи"]}
              onToggle={() => {
                toggleAppMenuSectionState('settings');
              }}
            />

            <SideMenuHelpSection
              favourites={favourites}
              removeFavourites={removeFavourites}
              addFavourites={addFavourites}
              open={sections.help}
              sections={["Интерактивное обучение", "Техподдержка", "Мы в Telegram", "Пожаловаться", "Хочу больше функционала"]}
              onToggle={() => {
                toggleAppMenuSectionState('help');
              }}
            />
          </div>
        </div>
      </div>

      <UserProfile />
    </div>
  );
};

export default memo(SideMenu);