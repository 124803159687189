import PropTypes from 'prop-types';
import { 
  getHumanDate,
  getHumanTime
}                from 'helpers/helpers';
import styles    from './ui-date.module.scss';

const UIDate = ({ date }) => {
  return (
    <div className={styles.component}>
      <div className={styles.date}>{getHumanDate(date)}</div>
      <div className={styles.time}>{getHumanTime(date)}</div>
    </div>
  )
};

UIDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default UIDate;