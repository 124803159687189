import { useTranslation } from 'next-i18next';
import styles             from './page-access-denied.module.scss';
import HttpsTwoToneIcon   from '@material-ui/icons/HttpsTwoTone';

const AccessDenied = () => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.component}>
      <div className={styles.icon}>
        <HttpsTwoToneIcon/>
      </div>
      <div className={styles.text}>
        {t('alerts.accessDenied')}
      </div>
    </div>
  )
}

export default AccessDenied;