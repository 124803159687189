import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import cns from 'classnames';
import styles from './garage-balance.module.scss';
import { Button } from 'semantic-ui-react';
import SelectTariffModal from './SelectTariff';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { setPaymentModalVisibility } from 'reducers/app'
import ButtonShepheld from 'components/Shepherd/components/ButtonShepheld';

const subscriptionStatuses = {
  trial: {
    buttonColor: 'orange'
  },
  actual: {
    buttonColor: 'green'
  },
  expiring: {
    buttonColor: 'orange'
  },
  expired: {
    buttonColor: 'red'
  }
}
const showWarningDaysCount = 7;

function GarageBalance({ data, update }) {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);
  const isPaymentModalVisible = useSelector(state => state.app.visiblePaymentModal);
  const createdWorkshopDate = useSelector(state => state.garage?.data?.createdAt);
  const now = new Date();
  const { subscriptionEndDate } = data;
  const daysLeft = Math.ceil((+new Date(subscriptionEndDate) - now) / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const daysAfterWorkshopRegistration = Math.floor((+now - new Date(createdWorkshopDate)) / (1000 * 60 * 60 * 24));
    const subscriptionDate = new Date(subscriptionEndDate);
    const different = +subscriptionDate - now;

    if (different < 0) {
      setSubscriptionStatus('expired');
    } else if (daysAfterWorkshopRegistration < 28 && different >= 0 && different <= 1000 * 60 * 60 * 24 * 28) {
      setSubscriptionStatus('trial');
    } else if (different > 1000 * 60 * 60 * 24 * showWarningDaysCount) {
      setSubscriptionStatus('actual');
    } else if (different < 1000 * 60 * 60 * 24 * showWarningDaysCount) {
      setSubscriptionStatus('expiring');
    }
  }, [subscriptionEndDate, createdWorkshopDate])

  const clickOpenPaymentModal = () => {
    dispatch(setPaymentModalVisibility(true))
  }

  return (
    <>
      <SelectTariffModal
        open={isPaymentModalVisible}
        onClose={() => {
          dispatch(setPaymentModalVisibility(false))
        }}
        billAccount={data}
      />

      <Choose>
        <When condition={subscriptionStatus === 'trial'}>
          <div className={cns(styles.alert, { warning: true })}>
            <div className={styles.title}>
              Пробный период
            </div>
            <div className={styles.text}>
              {t(`common.billService.daysLeft${daysLeft === 0 ? '_zero' : daysLeft === 1 ? '_one' : daysLeft > 3 ? '_other' : '_few'}`, { count: daysLeft })}. Оплатите подписку, чтобы продолжить работу после окончания пробного периода.
            </div>
            <div className={styles.actions}>
              <Button
                color={subscriptionStatuses[subscriptionStatus]?.buttonColor}
                size="mini"
                className="v2"
                fluid
                onClick={clickOpenPaymentModal}
              >
                Оплатить
              </Button>
            </div>

          </div>
        </When>
        <When condition={subscriptionStatus === 'expired'}>
          <div className={cns(styles.alert, { danger: true })}>
            <div className={styles.title}>
              Подписка истекла
            </div>
            <div className={styles.text}>
              Для продолжения работы необходимо продлить подписку
            </div>
            <div className={styles.actions}>
              <Button
                color={subscriptionStatuses[subscriptionStatus]?.buttonColor}
                size="mini"
                className="v2"
                fluid
                onClick={clickOpenPaymentModal}
              >
                Продлить
              </Button>
            </div>
          </div>
        </When>
        <When condition={subscriptionStatus === 'expiring'}>
          <div className={cns(styles.alert, { warning: true })}>
            <div className={styles.title}>
              Подписка истекает
            </div>
            <div className={styles.text}>
              {t(`common.billService.underDaysLeft${daysLeft === 0 ? '_zero' : daysLeft === 1 ? '_one' : daysLeft > 3 ? '_other' : '_few'}`, { count: daysLeft })}. Продлите подписку заранее.
            </div>
            <div className={styles.actions}>
              <Button
                color={subscriptionStatuses[subscriptionStatus]?.buttonColor}
                size="mini"
                className="v2"
                fluid
                onClick={clickOpenPaymentModal}
              >
                Продлить
              </Button>
            </div>
          </div>
        </When>
      </Choose>
      <If condition={subscriptionStatus === 'trial'}>
        <ButtonShepheld />
      </If>
    </>
  );
}

export default GarageBalance;