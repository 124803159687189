import { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { PROJECT_NAME } from 'helpers/constants';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import PageAccessDenied from './AccessDenied';
import * as Layouts from 'layouts';
import styles from './page.module.scss';

import CloseIcon from '@material-ui/icons/Close';
import { isLocalStorageAvailable } from 'helpers';
import { useProductEvents } from 'hooks';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import addSteps from 'components/Shepherd/shepherd/steps';


const ShepherdTour = dynamic(() => import("components/Shepherd/shepherd").then((item) => item.TourProvider), { ssr: false });

const Page = ({ user, auth, roles, title, children }) => {
  const isAvailableForRole = () => {
    // ROLES
    // ['mechanic', 'master', 'director', 'cashier', 'zapchastist']
    if (user !== false) {
      const userAllowedRoles = user.roles.filter(role => roles.includes(role));

      return !isEmpty(userAllowedRoles) || isEmpty(roles);
    }

    return false;
  };




  const getPageTitle = (_isAvailableForRole) => {
    let _title = `${title}`;

    if (!_isAvailableForRole) {
      _title = 'Access denied';
    }

    return isUndefined(_title) ? PROJECT_NAME : `${_title} | ${PROJECT_NAME}`;
  };

  const getLayout = () => {
    const type = user ? user.type : 'none';
    let layout;

    switch (type) {
      case 'employee':
        layout = Layouts.EmployeeLayout;
        break;
    }

    return layout;
  };

  const Layout = getLayout();
  const _isAvailableForRole = isAvailableForRole();

  const tourOptions = {
    defaultStepOptions: {
      cancelIcon: {
        enabled: true
      }
    },
    useModalOverlay: true
  };
  return (
    <div className={styles.component}>
      <ShepherdTour
        settings={{
          tourName: "Onboarding",
          useModalOverlay: true,
          keyboardNavigation: false,
          defaultStepOptions: {
            canClickTarget: false,
          }
        }}
        stepConstructor={addSteps}
      >

        <Head>
          <title>{getPageTitle(_isAvailableForRole)}</title>
        </Head>



        <If condition={Layout && !isEmpty(auth.profile)}>
          <Layout
            user={user}
            title={title}
          >


            <Choose>
              <When condition={_isAvailableForRole}>
                {children}

              </When>
              <Otherwise>
                <PageAccessDenied />
              </Otherwise>
            </Choose>
          </Layout>
        </If >
      </ShepherdTour>
    </div >
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  accessDeniedClb: PropTypes.func,
};

Page.defaultProps = {
  roles: [],
  accessDeniedClb: () => {
  },
};

export default memo(Page);