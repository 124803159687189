import { memo } from 'react';
import { useTranslation } from 'next-i18next';
import SideMenuSection from './../../Section';
import SideMenuLeaf from './../../Leaf';
import { useProductEvents } from 'hooks';
import ReferenceIcon from '@material-ui/icons/HelpOutlineOutlined';
import SupportIcon from '@material-ui/icons/QuestionAnswerOutlined';
import TelegramIcon from '@material-ui/icons/Telegram';
import IdeaIcon from '@material-ui/icons/EmojiObjectsOutlined';
import EditNote from '@material-ui/icons/Edit';
// import NoteAltIcon from '@mui/icons-material/NoteAlt';
import TourIcon from '@mui/icons-material/Tour';
import FmdBadIcon from '@mui/icons-material/FmdBad';

import getSideMenuSection from '../Favourites/list';

import { ROLES, UserAccess } from 'components/User';


const SideMenuHelpSection = ({ open, onToggle, sections, favourites, addFavourites, removeFavourites }) => {
  const { t, i18n } = useTranslation('common');
  const events = useProductEvents();

  const getGoogleDocLink = () => {
    const { language } = i18n;
    const dict = {
      ru: 'https://zencar.atlassian.net/l/c/xSNUAqU6',
      en: 'https://zencar.atlassian.net/l/c/xSNUAqU6'
    };

    return dict[language];
  };

  return (
    <SideMenuSection
      label={t('employee.menu.help')}
      open={open}
      onToggle={onToggle}
    >
      <div>

        {
          sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }
      </div>

      {/*<SideMenuLeaf*/}
      {/*  onClick={() => {*/}
      {/*    events.send("FAQ_open_workshop")*/}
      {/*  }}*/}
      {/*  icon={<ReferenceIcon/>}*/}
      {/*  text={t('employee.menu.reference')}*/}
      {/*  route={getGoogleDocLink()}*/}
      {/*  asSimpleLink={true}*/}
      {/*/>*/}

      {/* <SideMenuLeaf
        onClick={() => {
          events.send(['amplitude'], "onboarding.start-help.workshop")
        }}
        icon={<FmdBadIcon />}
        text='Интерактивное обучение'
        route='/repairs/new?tour=true'
      />

      <UserAccess roles={[ROLES.director, ROLES.master]}>

        <SideMenuLeaf
          onClick={() => {
            events.send(['amplitude'], "onboarding.start-help.workshop")
          }}
          icon={<FmdBadIcon />}
          text='Интерактивное обучение'
          route='/repairs/new?tour=true'
        />
      </UserAccess>

      <SideMenuLeaf
        onClick={() => {
          events.send("support_open_workshop")
        }}
        icon={<SupportIcon />}
        text={t('employee.menu.support')}
        route='/support'
      />
      <SideMenuLeaf
        asSimpleLink
        icon={<TelegramIcon />}
        text={t('employee.menu.telegram')}
        route='https://t.me/workshop_zencar'
      />
      <SideMenuLeaf
        icon={<EditNote />}
        text={'Пожаловаться'}
        route="/suggest-improvement?complaint=true"
        onClick={() => { events.sendTo(['amplitude'], 'menu.complaint-click.workshop') }}
      />
      <SideMenuLeaf
        icon={<IdeaIcon />}
        text={"Хочу больше функционала"}
        route="/suggest-improvement"
        onClick={() => { events.sendTo(['amplitude'], 'menu.more.functional-click.workshop') }}
      /> */}


    </SideMenuSection>
  );
};

export default memo(SideMenuHelpSection);