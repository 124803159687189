import {
  useCallback,
  useRef,
  memo
} from 'react';
import PropTypes from 'prop-types';
import cns from 'classnames';
import styles from './vehicle-logo.module.scss';

const VehicleLogo = ({ data, size = "small" }) => {
  const image = useRef(null);

  const getMarkName = useCallback(() => {
    if (data.manufacturer) {
      return data.manufacturer.name;
    }
    else { return "нет данных" }
  });

  const getImageFileName = useCallback(() => {
    const name = getMarkName().toLowerCase();

    return name.replace(/ /g, '-');
  });

  const onImageError = useCallback(() => {
    image.current.src = '/images/brands/nologo.svg';
  });

  return (
    <div className={cns(styles.component, styles[size])}>
      <img
        ref={image}
        src={`/images/brands/${getImageFileName()}.png`}
        onError={onImageError}
        alt=""
      />
    </div>
  )
};

VehicleLogo.propTypes = {
  data: PropTypes.object.isRequired,
  size: PropTypes.oneOf(['large', 'medium', 'small'])
}

export default memo(VehicleLogo);