import { memo }  from 'react';
import PropTypes from 'prop-types';
import UIList    from 'components/UI/List';
import UserItem  from 'components/User/Item';
import styles    from './users-list.module.scss';

const UsersList = ({ data }) => {
  return (
    <div className={styles.component}>
      <UIList data={data} itemComponent={UserItem}/>
    </div>
  )
};

UsersList.propTypes = {
  data: PropTypes.array
};

UsersList.defaultProps = {
  data: []
};

export default memo(UsersList);