import { memo }  from 'react';
import PropTypes from 'prop-types';
import styles    from './vehicle-plate.module.scss';

const VehiclePlate = ({ value }) => (
  <div className={styles.component}>
    {value ? value : 'не указан'}
  </div>
);

VehiclePlate.propTypes = {
  value: PropTypes.string
};

export default memo(VehiclePlate);