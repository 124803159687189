import { useTranslation } from 'next-i18next';
import CounterpartyItem   from 'components/Counterparty/Item';
import UICircleButton     from 'components/UI/CircleButton';
import styles             from './finder-counterparty-item.module.scss';
import common             from './../../finder.module.scss';

import MoveToInboxIcon    from '@material-ui/icons/MoveToInbox';

function FinderCounterpartyItem ({ data, closeFinder }) {
  const { t } = useTranslation('common');

  return (
    <div className={styles.root}>
      <a
        className={styles.link}
        href={`/clients/counterparties/details?id=${data.id}`}
        target="_blank"
      >
        <CounterpartyItem data={data}/>
      </a>

      <div className={common.actions}>
        <UICircleButton
          icon={<MoveToInboxIcon/>}
          size="medium"
          positive
          route={`/stock/operations/receive/new?counterpartyId=${data.id}`}
          title={t('common.depot.newReceiveDepotOperation')}
          onClick={closeFinder}
        />
      </div>
    </div>
    
  )
};

export default FinderCounterpartyItem;