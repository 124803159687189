import { useState, useEffect } from 'react';
import PropTypes               from 'prop-types';

const UIDeferred = ({ delay, children }) => {
  const [ visible, setVisible ] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {setVisible(true)}, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <If condition={visible}>
      {children}
    </If>
  )
};

UIDeferred.propTypes = {
  delay: PropTypes.number  
};

UIDeferred.defaultProps = {
  delay: 1500
};

export default UIDeferred;