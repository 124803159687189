import { memo }                      from 'react';
import PropTypes                     from 'prop-types';
import { useTranslation }            from 'next-i18next';
import { useUser }                   from 'hooks';
import { Dropdown }                  from 'semantic-ui-react';
import UINotifications               from 'components/UI/Notifications';
import { ROLES }                     from 'components/User';
import cns                           from 'classnames';
import { RepairStatusesTransitions } from 'helpers/constants';
import { EnumType }                  from 'json-to-graphql-query';
import isEmpty                       from 'lodash/isEmpty';
import without                       from 'lodash/without';
import styles                        from './repair-item-params.module.scss';

const RepairItemParams = ({ 
  data, changeStatus, update, employees,
  changePerformer, canEdit
}) => {
  const { t } = useTranslation('common');
  const user = useUser();

  const _getPerformerName = (name) => {
    let _name = name.last;

    if (name.first) {
      _name += ` ${name.first[0]}.`;
    }

    if (name.middle) {
      _name += `${name.middle[0]}.`;
    }

    return _name;
  };

  const _changeStatus = (status) => {
    let changeStatusText = t('common.repair.changeStatusConfirm', {
      from: t(`common.repair.statuses.${data.status}`),
      to: t(`common.repair.statuses.${status}`)
    });

    if (status === 'canceled') {
      changeStatusText = `${t('common.repair.alerts.cancel')} ${changeStatusText}`;
    }

    if (confirm(changeStatusText)) {
      changeStatus(data.id, new EnumType(status))
      .then(update)
      .then((res) => {
        if (status === 'completed') {
          UINotifications.show({
            type: "info",            
            text: t('common.repair.completedStatusNoty')
          });
        }
      });
    }
  };

  let availableStatuses = RepairStatusesTransitions[data.status];

  if (!user.oneOfRole([ ROLES.director ])) {
    availableStatuses = without(availableStatuses, 'canceled');
  }

  return (
    <div className={cns(styles.component, styles[data.status])}>
      <div className={cns(styles.param, styles.id)}>
        <div className={styles.param_label}>ID</div>
        <div className={styles.param_content}>
          {data.id}
        </div>
      </div>
      <div className={cns(styles.param, styles.status)}>
        <div className={styles.param_label}>
          {t('common.repair.status')}
        </div>
        <div className={styles.param_content}>
          <Choose>
            <When condition={
              !isEmpty(availableStatuses) &&
              !(data.clientInvoice && data.clientInvoice.paid === false) &&
              canEdit
            }>
              <Dropdown text={t(`common.repair.statuses.${data.status}`)}>
                <Dropdown.Menu>
                  <For each="status" of={availableStatuses} index="index">
                    <Dropdown.Item 
                      key={status}
                      text={t(`common.repair.statuses.${status}`)}
                      onClick={(e) => {
                        _changeStatus(status);
                      }}
                    />
                  </For>
                </Dropdown.Menu>
              </Dropdown>
            </When>
            <Otherwise>
              <div className={cns("ui dropdown", styles.default_cursor)}>
                <div className="text">
                  {t(`common.repair.statuses.${data.status}`)}
                </div>
              </div>
            </Otherwise>
          </Choose>
        </div>
      </div>
      <div className={cns(styles.param, styles.performer)}>
        <div className={styles.param_label}>
          {t('common.repair.performer')}
        </div>
        <div className={styles.param_content}>
          <Choose>
            <When condition={data.status !== 'closed' && canEdit}>
              <Dropdown 
                text={_getPerformerName(data.performer.name)}
                direction="left"
              >
                <Dropdown.Menu>
                  <For 
                    index="index"
                    each="employee" 
                    of={employees.filter(item => item.id !== user.id)}
                  >
                    <Dropdown.Item 
                      key={employee.id}
                      text={_getPerformerName(employee.name)}
                      onClick={(e) => {
                        changePerformer(data.id, employee.id).then(update);
                      }}
                    />
                  </For>
                </Dropdown.Menu>
              </Dropdown>
            </When>
            <Otherwise>
              <div className={cns("ui dropdown", styles.default_cursor)}>
                <div className="text">
                  {_getPerformerName(data.performer.name)}
                </div>
              </div>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </div>
  )
};

RepairItemParams.propTypes = {
  canEdit: PropTypes.bool
};

RepairItemParams.defaultProps = {
  canEdit: true
};

export default memo(RepairItemParams);