import {
  useDispatch,
}                     from 'react-redux';
import DepotEditModal from './Component';
import API            from 'api';

const ConnectedDepotEditModal = (props) => {
  const dispatch = useDispatch();

  const save = (model) => {
    if (model.id) {
      return dispatch(API.mutations.updateDepot({input: model}));
    } else {
      return dispatch(API.mutations.createDepot({input: model}));
    }
  };

  const getDepots = () => dispatch(API.queries.depots());

  return (
    <DepotEditModal {...props} save={save} getDepots={getDepots}/>
  );
};

export default ConnectedDepotEditModal;