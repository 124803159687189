import {
  useEffect,
  useState,
}                         from 'react';
import PropTypes          from 'prop-types';
import {
  Button,
  Modal,
}                         from 'semantic-ui-react';
import UIModalClose       from 'components/UI/ModalClose';
import styles             from './select-tariff-modal.module.scss';
import API                from 'api';
import {
  useDispatch,
  useSelector,
}                         from 'react-redux';
import cns                from 'classnames';
import { parse }          from 'tinyduration';
import { formatDuration } from 'date-fns';
import { ru }             from 'date-fns/locale';
import { formatNumber }   from 'helpers';
import { EnumType }       from 'json-to-graphql-query';

const baseMonthPrice = 7990;

const SelectTariffModal = ({open, onClose, billAccount}) => {
  const dispatch = useDispatch();
  const tariffs = useSelector(state => state.billCommoditys?.data?.items);
  const [selectedTariffId, setSelectedTariffId] = useState();

  useEffect(() => {
    dispatch(API.queries.billCommoditys({order: {price: {amount: new EnumType('asc')} }}));
  }, []);

  const getMonthPrice = (tariff) => {
    const commodityPrice = tariff.price?.amount;
    const durationObject = parse(tariff.duration);
    const {years, months} = durationObject;

    if (years) {
      return commodityPrice/(years * 12);
    }
    if (months && months > 1) {
      return commodityPrice/months;
    }

    return commodityPrice;
  };

  const onPayment = () => {
    const input = {
      billAccountId: billAccount.id,
      billCommodityId: selectedTariffId,
    };
    dispatch(API.mutations.createBillPayment({input}))
      .then((resp) => {
        const paymentUrl = resp?.data?.createBillPayment?.paymentUrl;
        if (paymentUrl) {
          onClose();
          const paymentWindow = window.open(paymentUrl, '_blank');
          paymentWindow.focus();
        }
      });
  };

  const getBenefit = (tariff) => {
    const durationObject = parse(tariff.duration);
    const {years, months} = durationObject;
    const commodityPrice = tariff.price?.amount;
    const fullPrice = years ? (years * 12 * baseMonthPrice) : months * baseMonthPrice;

    return fullPrice - commodityPrice;
  }

  return (
    <Modal
      className={styles.root}
      closeIcon={<UIModalClose onClick={onClose}/>}
      size="tiny"
      open={open}
      centered={true}
      onClose={onClose}
      closeOnEscape={true}
      closeOnDimmerClick={false}
    >
      <Modal.Header>
        Оплата подписки
      </Modal.Header>
      <Modal.Content>
        <div className={styles.tariffs}>
          <For each="tariff" of={tariffs || []}>
            <div
              key={tariff.id}
              className={cns(styles.tariff, {active: tariff.id === selectedTariffId})}
              onClick={() => {
                setSelectedTariffId(tariff.id);
              }}
            >
              <div className={styles.top_row}>
                <If condition={tariff.id === selectedTariffId}>
                  <div className={styles.select_icon_container}>
                    <img src={'/images/svg/selected.svg'} width="14" height="14" alt="checked"/>
                  </div>

                </If>
                <If condition={tariff.id !== selectedTariffId}>
                  <div className={styles.select_icon_container}>
                    <div className={styles.select_circle}/>
                  </div>
                </If>
                <div className={styles.duration}>
                  {formatDuration(parse(tariff.duration), {locale: ru})}
                </div>
              </div>
              <div className={styles.bottom_row}>
                <div className={styles.month_price}>
                  {formatNumber(getMonthPrice(tariff))} <span className={styles.month_price_label}>₽/мес</span>
                </div>
                <If condition={getBenefit(tariff)}>
                  <div className={styles.price_block}>
                    <div className={styles.label}>Экономия</div>
                    <div className={styles.price_value}>{formatNumber(getBenefit(tariff))} ₽</div>
                  </div>
                </If>
                <div className={styles.price_block}>
                  <div className={styles.label}>К оплате</div>
                  <div className={styles.price_value}>{formatNumber(tariff.price.amount)} ₽</div>
                </div>
              </div>
            </div>
          </For>
        </div>

        <div className={styles.actions}>
          <Button color="teal" size="tiny" onClick={onPayment}>
            Оплатить
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

SelectTariffModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SelectTariffModal.defaultProps = {
  open: false,
};

export default SelectTariffModal;