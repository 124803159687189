import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import SideMenuSection from './../../Section';
import SideMenuLeaf from './../../Leaf';
import {
  ROLES,
  UserAccess,
} from 'components/User';
import DepotEditModal from 'components/Depot/Depots/EditModal';
import ShippingIcon from '@material-ui/icons/LocalShippingOutlined';
import Inbox from '@material-ui/icons/Inbox';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import {
  CategoryOutlined,
  DnsOutlined,
  HistoryOutlined,

} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import getSideMenuSection from '../Favourites/list';

const SideMenuDepotSection = ({ open, onToggle, addressGarage, sections, favourites, addFavourites, removeFavourites }) => {
  const { t } = useTranslation('common');
  const [modalOpened, setModalOpened] = useState(false);

  return (
    <>
      <DepotEditModal
        data={{}}
        open={modalOpened}
        onClose={() => {
          setModalOpened(false);
        }}
      />

      <SideMenuSection
        label={t('employee.menu.stock')}
        open={open}
        onToggle={onToggle}
      >
        <div>

          {
            sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
          }
        </div>
        {/* <SideMenuLeaf
          icon={<ShippingIcon />}
          text={t('common.depot.operations.receive')}
          route="/stock/operations/receive"
        />
        <SideMenuLeaf
          icon={<DnsOutlined />}
          text={t('employee.menu.depots')}
          route="/stock"
        />
        <SideMenuLeaf
          icon={<Inbox />}
          text={t('employee.menu.products')}
          route="/stock/products?tab=available"
        />

        <SideMenuLeaf
          icon={<ListAltOutlinedIcon />}
          route="/settings/suppliers_parts"
          text="Поставщики"
        />
        <SideMenuLeaf
          icon={<CategoryOutlined />}
          text={t('employee.menu.stocks')}
          route="/stock/stocks"
        />
        <SideMenuLeaf
          icon={<AddIcon />}
          text={t('employee.menu.orderParts')}
          route="/stock/order-parts?tab=ordered"
        /> */}

        {/*<UserAccess roles={[ROLES.director, ROLES.master, ROLES.zapchastist, ROLES.cashier]}>*/}
        {/*  <For each="depot" of={depotsData} index="index">*/}
        {/*    <SideMenuLeaf*/}
        {/*      key={depot.id}*/}
        {/*      icon={<FolderOpenTwoToneIcon/>}*/}
        {/*      route={`/stock/depots/details?id=${(depot.id).toString(16)}&tab=stock`}*/}
        {/*      text={depot.name}*/}
        {/*    />*/}
        {/*  </For>*/}
        {/*</UserAccess>*/}

      </SideMenuSection >
    </>
  );
};

SideMenuDepotSection.propTypes = {
  depots: PropTypes.object.isRequired,
};

export default SideMenuDepotSection;