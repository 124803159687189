import { memo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useUser } from 'hooks';
import Link from 'next/link';
import RepairItemParams from './Params';
import { ROLES } from 'components/User';
import VehicleSimple from 'components/Vehicle/Simple';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import { formatNumber } from 'helpers/helpers';
import format from 'date-fns/format';
import styles from './repair-list-item.module.scss';

const RepairListItem = ({
  data, canEdit, update, onClick, asSimpleLink, linkProps
}) => {

  const { t } = useTranslation('common');
  const user = useUser();
  const href = `/repairs/details?id=${data.id}`;

  const _onClick = (e) => {
    if (isFunction(onClick)) {
      e.preventDefault();
      e.stopPropagation();
      onClick(data);
    }
  };

  const getLink = (children) => {
    if (asSimpleLink) {
      return cloneElement(children, { href, ...linkProps });
    }

    return (
      <Link href={href}>
        {children}
      </Link>
    )
  };

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        {getLink(
          <a className={styles.vehicle_wrapper} onClick={_onClick}>
            <VehicleSimple data={data.vehicle} />
          </a>
        )}

        <RepairItemParams
          data={data}
          canEdit={user.oneOfRole([ROLES.director, ROLES.master]) && canEdit}
          update={update}
        />

        {getLink(
          <a className={styles.description} onClick={_onClick}>
            <Choose>
              <When condition={isNull(data.description)}>
                {t('common.repair.emptyDescription')}
              </When>
              <Otherwise>
                {
                  data.description && data.description.length > 80 ?
                    `${data.description.substring(0, 80)}...` : data.description
                }
              </Otherwise>
            </Choose>
          </a>
        )}

        {getLink(
          <a className={styles.footer} onClick={_onClick}>
            <div className={styles.date}>
              <Choose>
                <When condition={data.status === 'closed'}>
                  {format(new Date(data.closedAt), 'dd.MM.yyyy HH:mm')}
                </When>
                <Otherwise>
                  {format(new Date(data.createdAt), 'dd.MM.yyyy HH:mm')}
                </Otherwise>
              </Choose>
            </div>
            <div className={styles.total}>
              <nobr>
                {formatNumber(data.totalPrice.amount)}{' '}
                {t(`currency.${data.totalPrice.currency}`)}
              </nobr>
            </div>
          </a>
        )}
      </div>
    </div>
  )
};

RepairListItem.propTypes = {
  data: PropTypes.object.isRequired,
  canEdit: PropTypes.bool,
  asSimpleLink: PropTypes.bool,
  link: PropTypes.object,
  onClick: PropTypes.func
};

RepairListItem.defaultProps = {
  update: () => { },
  canEdit: true,
  asSimpleLink: false,
  linkProps: {}
};

export default memo(RepairListItem);