import { memo } from 'react';
import PropTypes from 'prop-types';
import VehiclePlate from 'components/Vehicle/Plate';
import VehicleLogo from 'components/Vehicle/Logo';
import isEmpty from 'lodash/isEmpty';
import styles from './vehicle-simple.module.scss';
import KindFormatter from '../../Formatters/Vehicle/KindFormatter';

const VehicleSimple = ({ data, showVIN, showPlate }) => {

  const model = data.model ? data.model : {};
  const markName = data.manufacturer?.name;
  const checkCarName = (data) => {

    if (data.modification) {
      return `${markName} ${model.name} ${model.subbody}`
    } else if (model && markName) {
      return `${markName} ${model.name}`
    } else {
      return 'данные не заполненны'
    }

  }

  return (
    <div className={styles.component}>
      <VehicleLogo data={data} />

      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.name}>
            {/* {`${markName} ${model.name} ${model.subbody}`} {KindFormatter({value: model.kind})} */}
            <span className={styles.vehicle_model_name}>{checkCarName(data)}</span> {KindFormatter({ value: model?.kind })}
          </div>
          <If condition={showVIN}>
            <div className={styles.vin}>
              {typeof data.frameNumber === 'string' && !isEmpty(data.frameNumber) ? data.frameNumber : data.vin}
            </div>
          </If>
        </div>

        <If condition={showPlate}>
          <VehiclePlate value={data.plate} />
        </If>
      </div>
    </div>
  )
};

VehicleSimple.propTypes = {
  data: PropTypes.object.isRequired,
  showVIN: PropTypes.bool,
  showPlate: PropTypes.bool
};

VehicleSimple.defaultProps = {
  showVIN: true,
  showPlate: true
};

export default memo(VehicleSimple);