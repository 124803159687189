import SideMenuSection from 'components/Navigation/SideMenu/Section';
import SideMenuLeaf from 'components/Navigation/SideMenu/Leaf';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useProductEvents, useUser } from 'hooks';
import UICircleButton from 'components/UI/CircleButton';
import {
  ROLES,
  UserAccess,
} from 'components/User';
import RvHookupOutlined from '@material-ui/icons/RvHookupOutlined'
import ClosedFolderIcon from '@material-ui/icons/FolderOpenTwoTone';
import AppealsIcon from '@material-ui/icons/MoveToInboxOutlined';
import StarHalfRoundedIcon from '@material-ui/icons/StarHalfRounded';
import NotificationImportantOutlinedIcon from '@material-ui/icons/NotificationImportantOutlined';
import { AddIcCallOutlined } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import BuildTwoTone from '@material-ui/icons/BuildTwoTone';
import NextWeekOutlined from '@material-ui/icons/NextWeekOutlined';
import NoteAddOutlined from '@material-ui/icons/NoteAddOutlined';
import AnalyticsIcon from '@material-ui/icons/PollOutlined';
import IdeaIcon from '@material-ui/icons/EmojiObjectsOutlined';
import PersonalVideoOutlinedIcon from '@material-ui/icons/PersonalVideoOutlined';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import getSideMenuSection from './list';
import { isLocalStorageAvailable } from 'helpers';
import { loadMenuFavourites } from 'reducers/app';
// import styles from './side-menu-favourites.scss'



const SideMenuFavouritesSection = ({ open, onToggle, sections, favourites, addFavourites, removeFavourites }) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const user = useUser();
  const intervalId = useRef(null);
  const events = useProductEvents();



  useEffect(() => {

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  return (
    <SideMenuSection
      label={'Избранное'}
      open={open}
      onToggle={onToggle}
      popover={{
        text: 'Раздел “Избранное” можно редактировать. При наведении курсора на выбранный раздел, отображается “Звездочка”, кликнув на нее, раздел появиться в “Избранном”. Если звездочка темная, значит раздел уже в “Избранном” и при клике, вы уберете его.',

        icon: <InfoIcon />
      }}
    >

      {/* <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<PersonalVideoOutlinedIcon />}
          id="workbench"
          text={'Рабочий стол'}
          route={'/workbench'}
          onClick={() => {
            events.send('open_workbench_desk_mp_workshop', { type: 'click' })
          }}
        />
      </UserAccess> */}

      {/* <UserAccess roles={[ROLES.director, ROLES.master, ROLES.zapchastist]}>
        <SideMenuLeaf
          favourite={true}
          icon={<AnalyticsIcon />}
          text={t('employee.menu.analytics')}
          route="/analytics"
        />
      </UserAccess> */}

      {/* <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<NextWeekOutlined />}
          text={'Создать заказ-наряд'}
          route="/repairs/new"
          onClick={() => { events.send('menu.create.order-click.workshop', { type: 'click' }) }}
        />
      </UserAccess> */}
      {/* 
      <UserAccess roles={[ROLES.director, ROLES.zapchastist]}>
        <SideMenuLeaf
          favourite={true}
          icon={<AddIcon />}
          text={'Заказ запчастей'}
          route="/stock/order-parts?tab=ordered"
          onClick={() => { events.send('menu.spare.parts.order-click.workshop', { type: 'click' }) }}
        />
      </UserAccess> */}

      {/* <UserAccess roles={[ROLES.director, ROLES.master]}>
        <SideMenuLeaf
          favourite={true}
          icon={<NoteAddOutlined />}
          text={'Записать клиента'}
          route={`/planning/arrivals/date/${dateQuery}?role=ALL&open=true`}
          onClick={() => { events.send('menu.customer.record-click.workshop', { type: 'click' }) }}
        />


      </UserAccess> */}
      <div>
        {
          sections.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }
        {
          favourites.map(item => getSideMenuSection(item, { favourites, addFavourites, removeFavourites }))
        }

      </div>
    </SideMenuSection>
  );
};

export default SideMenuFavouritesSection;