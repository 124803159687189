import { memo }         from 'react';
import PropTypes        from 'prop-types';
import UIList           from 'components/UI/List';
import VehiclePlateItem from 'components/Vehicle/Plate/HistoryItem';
import styles           from './vehicle-plate-history.module.scss';

const VehiclePlateHistory = ({ data = [] }) => (
  <div className={styles.component}>
    <UIList data={data} itemComponent={VehiclePlateItem}/>
  </div>
);

VehiclePlateHistory.propTypes = {
  data: PropTypes.array
};

export default memo(VehiclePlateHistory);