import { conformToMask } from 'react-text-mask';
import isEmpty from 'lodash/isEmpty';

const maskRF = ['+', /\d/, ' ', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
const maskBel = ['+', /\d/, /\d/, /\d/, ' ', '(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]
const PhoneFormatter = ({ value, asLink = true }) => (
  <span>
    <If condition={!isEmpty(value)}>
      <Choose>
        <When condition={asLink}>
          <a
            href={`tel:${value}`}
            target="_blank"
            onClick={e => e.stopPropagation()}
            style={{
              textDecoration: 'underline'
            }}
          >
            <If condition={(value[0] === '3' && value.length === 12) || (value[0] === '+' && value.length === 13)}>
              <nobr>{conformToMask(value, maskBel).conformedValue}</nobr>
            </If>

            <If condition={(value[0] === '+' && value.length === 12) || value.length === 11}>
              <nobr>{conformToMask(value, maskRF).conformedValue}</nobr>
            </If>

          </a>
        </When>
        <Otherwise>
          <If condition={(value[0] === '3' && value.length === 12) || (value[0] === '+' && value.length === 13)}>
            <nobr>{conformToMask(value, maskBel).conformedValue}</nobr>
          </If>
          <If condition={(value[0] === '+' && value.length === 12) || value.length === 11}>
            <nobr>{conformToMask(value, maskRF).conformedValue}</nobr>
          </If>
        </Otherwise>
      </Choose>
    </If>
  </span>
);

export default PhoneFormatter;